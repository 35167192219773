import { Outlet } from "react-router-dom";
import { Modal, Button, Layout, Menu, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { HashRouter, Link, Route, Routes } from "react-router-dom";
import { Category } from "../Models";
import { styles } from "../styles/styles";
import { router } from "../routes/router";
import { ChangePassword } from "../modals/ChangePassword";
import { CategoryService, Network } from "../services";
import { getCatFranceName } from "../utils/get_cat_france_name";
import { logoutRequest } from "../store/actions/authActions/authActions";

const { Footer, Sider, Content } = Layout;
export default function RechLayout() {
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isVisibleChangePassword, setIsVisibleChangePassword] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Confirmez-vous la déconnexion",
      okText: "Déconnecter",
      cancelText: "Annuler",
      onOk: () => {
        dispatch(logoutRequest());
      },
    });
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    if (width <= 768) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [window.innerWidth]);
  useEffect(() => {
    getCategories();
  }, []);
  const getCategories = () => {
    try {
      const itemsCat: {}[] = [];
      itemsCat.push({
        key: Math.random() + "".replace(".", ""),
        label: (
          <Link to="/research/export" style={{ textTransform: "capitalize" }}>
            Export
          </Link>
        ),

        icon: <i className="fas fa-file-export" style={styles.menuIcon}></i>,
      });
      itemsCat.push({
        key: Math.random() + "".replace(".", ""),
        label: (
          <Link to="/research/appcode" style={{ textTransform: "capitalize" }}>
            Gestion des codes
          </Link>
        ),

        icon: <i className="fas fa-barcode" style={styles.menuIcon}></i>,
      });
      itemsCat.push({
        key: Math.random() + "".replace(".", ""),
        label: (
          <Link to="/research/tracking" style={{ textTransform: "capitalize" }}>
            Tableau de suivie
          </Link>
        ),

        icon: <i className="fas fa-chart-line" style={styles.menuIcon}></i>,
      });
      setMenuItems(itemsCat);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Layout>
        <Sider
          width={250}
          collapsed={isCollapsed}
          style={{
            minHeight: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Row justify="center" style={{ padding: 10 }}>
            {isCollapsed || (
              <Link to="/">
                <h1
                  style={{
                    textTransform: "uppercase",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 24,
                  }}
                >
                  TechCare
                </h1>
              </Link>
            )}
          </Row>
          <Menu theme="dark" mode="inline" items={menuItems} />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              left: 0,
              padding: "20px 0",
            }}
          >
            <div className="text-center">
              <p className="text-capitalize text-light">
                Bonjour {localStorage.getItem("username")}
              </p>
              <>
                <button
                  className="btn btn-sm btn-light"
                  style={{
                    backgroundColor: "#000b14",
                    border: "none",
                    color: "#ecf0f1",
                    padding: "5px 20px",
                  }}
                  onClick={handleLogout}
                >
                  <i className="fas fa-power-off"></i>{" "}
                  {isCollapsed ? "" : "Déconnecter"}
                </button>
                <br />
              </>
            </div>
          </div>
        </Sider>
        <Layout style={{ marginLeft: isCollapsed ? 80 : 250 }}>
          <Content style={styles.bodyComponent}>
            <Outlet />
          </Content>
          <Footer style={{ backgroundColor: "#212121", color: "#fafafa" }}>
            <Row justify="center">
              <p>
                <i>&copy; 2022 | TechCare</i>
              </p>
              {/* <p style={{ padding: '0 20px' }}>
              Created by VNTT Solution - TDM City - Binh Duong Provice
            </p> */}
            </Row>
          </Footer>
        </Layout>
        <ChangePassword
          visible={isVisibleChangePassword}
          onCancel={() => setIsVisibleChangePassword(false)}
        />
      </Layout>
    </>
  );
}
