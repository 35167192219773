import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Badge, Button, Card, Col, List, message, Modal, Row } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useEffect, useState } from 'react'
import { Contact } from '../Models'
import { url } from '../Models/urls'
import { styles } from '../styles/styles'
import { HeaderContent } from '../components/HeaderContent'
import { UpserContacModal } from '../modals'
import {
  ContactSevice,
  DeleteContactSevice,
} from '../services/Contacts/ContactSevice'

import { Network } from '../services/network'

export const Contacts = () => {
  const [isVisibleModalAddContact, setIsVisibleModalAddContact] = useState(
    false,
  )
  const [contacts, setContacts] = useState<Contact[]>([])
  const [isVisibleContact, setIsVisibleContact] = useState(false)
  const [contact, setContact] = useState<Contact>()

  useEffect(() => {
    fetchContact()
  }, [])

  const showModalAddContact = (contact?: Contact) => {
    setContact(contact)
    setIsVisibleModalAddContact(true)
  }

  const closeModalAddContact = () => {
    setIsVisibleModalAddContact(false)
  }

  const fetchContact = async () => {
    try {
      const res = await ContactSevice.getContactService()
      if (Network.isSuccess(res.status)) {
        setContacts(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const showModalContact = (contact: Contact) => {
    setContact(contact)
    setIsVisibleContact(true)
  }

  const delContact = async (id: string) => {
    Modal.confirm({
      title: 'Attention',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirmez-vous la suppression',
      okText: 'Supprimer',
      cancelText: 'Annuler',
      onOk: async () => {
        try {
          await DeleteContactSevice.deleteContactService(id).then(() => {
            fetchContact()
            message.success('Suppression avec succès!')
          })
        } catch (error) {
          console.log(error)
        }
      },
    })
  }
  return (
    <Content style={styles.bodyComponent}>
      <HeaderContent
        title="Contacts"
        button={
          <button
            className="btn btm-sm btn-primary"
            key="btnheader1"
            onClick={() => showModalAddContact()}
          >
            <i className="fas fa-address-book"></i> Nouveau
          </button>
        }
      />

      <div className="content">
        <Card>
          <List>
            {contacts.map((contact: Contact) => (
              <List.Item
                key={contact._id}
                actions={
                  //if contact is NPC then show delete button else null
                  !contact.isNPC
                    ? [
                        <Button
                          key="btn1"
                          type="primary"
                          ghost
                          icon={<EditOutlined className="icon-button" />}
                          onClick={() => showModalAddContact(contact)}
                        ></Button>,
                        <Button
                          key="bnt2"
                          type="primary"
                          danger
                          icon={<DeleteOutlined className="ion-button" />}
                          onClick={() => delContact(contact._id)}
                        ></Button>,
                      ]
                    : [
                        <Button
                          key="editNpc"
                          type="primary"
                          ghost
                          icon={<EditOutlined className="icon-button" />}
                          onClick={() => showModalAddContact(contact)}
                        ></Button>,
                      ]
                }
              >
                <List.Item.Meta
                  avatar={
                    contact.image ? (
                      <img
                        src={url + contact.image}
                        style={{
                          width: 45,
                          height: 45,
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                        alt={contact.name}
                      />
                    ) : null
                  }
                  title={
                    <a
                      style={{ fontSize: 20 }}
                      onClick={() => showModalContact(contact)}
                    >
                      {contact.isNPC ? (
                        <Badge.Ribbon color="green" text="Personnage du jeu">
                          {contact.name}
                        </Badge.Ribbon>
                      ) : (
                        contact.name
                      )}
                    </a>
                  }
                  description={contact.phone}
                />
              </List.Item>
            ))}
          </List>
        </Card>
      </div>

      <UpserContacModal
        contact={contact}
        isVisible={isVisibleModalAddContact}
        onCancel={closeModalAddContact}
        fetchContact={fetchContact}
      />

      <Modal
        getContainer={false}
        footer={null}
        visible={isVisibleContact}
        onCancel={() => setIsVisibleContact(false)}
      >
        <Row justify="center" style={{ padding: '20px 0' }}>
          <Col style={{ textAlign: 'center' }}>
            {contact?.image ? (
              <img
                src={url + contact?.image}
                style={{
                  width: 150,
                  height: 150,
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
            ) : null}

            <h2>{contact?.name}</h2>
          </Col>
        </Row>
        <p>Téléphone</p>
        <h1>{contact?.phone}</h1>
      </Modal>
    </Content>
  )
}
