import { AxiosResponse } from 'axios'
import { Local } from '../../Models/GameSource/Location'
import { Network } from '../network'

export class LocationSevice {
  static async getLocalService(): Promise<AxiosResponse<Local[]>> {
    return await Network.getWithToken<Local[]>({
      url: '/getLocation',
    })
  }
}

export class DeleteLocationSevice {
  static async deleteLocalService(id: string): Promise<AxiosResponse<Local>> {
    return await Network.deleteWithToken<Local>({
      url: '/note/' + id,
    })
  }
}

export class PostLocationSevice {
  static async postLocalService(data: FormData): Promise<AxiosResponse<Local>> {
    return await Network.postWithToken<Local>({
      url: '/note',
      data: data,
    })
  }
}

export class PutLocationSevice {
  static async putLocalService(data: FormData): Promise<AxiosResponse<Local>> {
    return await Network.putWithToken<Local>({
      url: '/note',
      data: data,
    })
  }
}
