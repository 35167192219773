import { Affix, PageHeader } from 'antd'

interface Props {
  title: string | undefined
  button?: any
}

export const HeaderContent = (props: Props) => {
  const { title, button } = props
  return (
    <Affix offsetTop={0}>
      <PageHeader
        title={title}
        // onBack={() => window.history.back()}
        style={{
          margin: 0,
          padding: 10,
          backgroundColor: '#f0f2f5',
          borderBottom: '1px solid #e9e9e9',
        }}
        extra={[button]}
      ></PageHeader>
    </Affix>
  )
}
