import { LoginPayload } from '../../../Models/Payload/LoginPayload'
import { authTypes } from '../../actionTypes/authTypes'
import {
  CheckAuthRequest,
  FetchAuthFailure,
  FetchAuthFailurePayload,
  FetchAuthRequest,
  FetchAuthSuccess,
  FetchAuthSuccessPayload,
  LogoutRequest,
} from '../../types/authTypes'

export const fetchAuthRequest = (payload: LoginPayload): FetchAuthRequest => ({
  type: authTypes.FETCH_AUTH_REQUEST,
  payload,
})

export const checkAuthRequest = (): CheckAuthRequest => ({
  type: authTypes.CHECK_AUTH_REQUEST,
})

export const fetchAuthSuccess = (
  payload: FetchAuthSuccessPayload,
): FetchAuthSuccess => ({
  type: authTypes.FETCH_AUTH_SUCCESS,
  payload,
})

export const fetchAuthFailure = (
  payload: FetchAuthFailurePayload,
): FetchAuthFailure => ({
  type: authTypes.FETCH_AUTH_FAILURE,
  payload,
})

export const logoutRequest = (): LogoutRequest => ({
  type: authTypes.LOGOUT_REQUEST,
})
