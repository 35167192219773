import React, { useEffect, useState } from "react";

import { HeaderContent } from "../../components/HeaderContent";
import UsersGrid from "./UsersGrid";
import { BASE_URL } from "../../utils/Constants";
import axios from "axios";
import { Account } from "../../Models";
import ModalUser from "./ModalUser";

export default function AppCodePage() {
  const [users, setusers] = useState<Account[]>([]);

  useEffect(() => {
    initUsers();
  }, []);
  const initUsers = async () => {
    try {
      const res: any = await axios.get(BASE_URL + "/admin-users");
      if (res && res.data) {
        const arr = res.data.map((it: any) => {
          return {
            ...it,
            key: it._id,
            avatar: "https://joeschmoe.io/api/v1/random",
          };
        });
        setusers(arr);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <HeaderContent
        title="Gestion des utilisateurs"
        button={<ModalUser initUsers={initUsers} />}
      />

      <div className="content">
        <UsersGrid users={users} initUsers={initUsers} />
      </div>
    </div>
  );
}
