import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import {
  Contacts,
  Dashboard,
  Locations,
  Notes,
  Notifications,
  PlayerAvatar,
  ProfileDetail,
  ProfilePage,
  ScreenDetail,
  ExportPage,
  EditExportPage,
  AppCodePage,
  TrackingPage,
  DashboardRech,
  ViewTrackDetailPage,
  UsersPage,
} from "./pages/Index";
import AdminLayout from "./layouts/AdminLayout";
import EmptyLayout from "./layouts/EmptyLayout";
import RechLayout from "./layouts/RechLayout";
import Login from "./auth/Login";
import Page404 from "./pages/error/Page404";
import Page401 from "./pages/error/Page401";
import RequireAdmin from "./auth/RequireAdmin";
import RequireRech from "./auth/RequireRech";
import RequireGuest from "./auth/RequireGuest";
import RequireAuth from "./auth/RequireAuth";
import AuthWaitingToRedirect from "./auth/AuthWaitingToRedirect";

export default function Router() {
  const appRoutes = useRoutes([
    /* admin */
    {
      path: "/admin",
      element: (
        <RequireAdmin>
          <AdminLayout />
        </RequireAdmin>
      ),
      children: [
        { path: "", element: <Dashboard /> },
        { path: "-player-account/:id", element: <PlayerAvatar /> },
        { path: "group-detective-messages/:id", element: <Notifications /> },
        { path: "leo-messages/:id", element: <Notifications /> },
        { path: "contacts", element: <Contacts /> },
        { path: "note", element: <Notes /> },
        { path: "screen-detail/:id/:name", element: <ScreenDetail /> },
        { path: "pop-up/:id", element: <Notifications /> },
        { path: "map-location", element: <Locations /> },
        { path: "pixagram-profiles", element: <ProfilePage /> },
        { path: "persional-detail/:id", element: <ProfileDetail /> },
        { path: "export", element: <ExportPage /> },
        { path: "export/:id", element: <EditExportPage /> },
        { path: "appcode", element: <AppCodePage /> },
        { path: "tracking", element: <TrackingPage /> },
        { path: "tracking/:appcode", element: <ViewTrackDetailPage /> },
        { path: "users", element: <UsersPage /> },
      ],
    },
    /* research */
    {
      path: "/research",
      element: (
        <RequireRech>
          <RechLayout />
        </RequireRech>
      ),
      children: [
        { path: "", element: <DashboardRech /> },
        { path: "export", element: <ExportPage /> },
        { path: "export/:id", element: <EditExportPage /> },
        { path: "appcode", element: <AppCodePage /> },
        { path: "tracking", element: <TrackingPage /> },
        { path: "tracking/:appcode", element: <ViewTrackDetailPage /> },
      ],
    },
    /** waiting after authenticate */
    {
      path: "/wating",
      element: (
        <RequireAuth>
          <AuthWaitingToRedirect />
        </RequireAuth>
      ),
    },
    /* general */
    {
      path: "/",
      element: (
        <RequireGuest>
          <EmptyLayout />
        </RequireGuest>
      ),
      children: [
        { path: "", element: <Login /> },
        { path: "login", element: <Login /> },
      ],
    },
    { path: "401", element: <Page401 /> },
    { path: "404", element: <Page404 /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
  return <>{appRoutes}</>;
}
