import "./App.css";
import "antd/dist/antd.min.css";
import { useEffect } from "react";
import { Modal } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store/reducers/rootReducer";
import {
  checkAuthRequest,
  fetchAuthRequest,
  logoutRequest,
} from "./store/actions/authActions/authActions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Router from "./routes";

//expiration 7 days
const nowDate = new Date().getTime();
const expiration = localStorage.getItem("expirationTime");
if (expiration) {
  if (nowDate >= parseInt(expiration)) {
    localStorage.clear();
  }
}
function App() {
  const dispatch = useDispatch();

  const { isAuthenticated, user, error } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    dispatch(checkAuthRequest());
  }, []);
  const handleLogout = () => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Confirmez-vous la déconnexion",
      okText: "Déconnecter",
      cancelText: "Annuler",
      onOk: () => {
        dispatch(logoutRequest());
      },
    });
  };

  return (
    <>
      <Router />
    </>
  );
}

export default App;
