import { AxiosResponse } from 'axios'
import { NewFeed } from '../../Models/GameSource/NewFeed'
import { Network } from '../network'

export class NewFeedSevice {
  static async getNewFeedService(): Promise<AxiosResponse<NewFeed[]>> {
    return await Network.getWithToken<NewFeed[]>({
      url: '/newFeed',
    })
  }
}

export class DeleteNewFeed {
  static async deleteNewFeed(id: string): Promise<AxiosResponse<NewFeed>> {
    return await Network.deleteWithToken<NewFeed>({
      url: '/newFeed/' + id,
    })
  }
}

export class PutNewFeedSevice {
  static async putNewFeed(data: FormData): Promise<AxiosResponse<NewFeed>> {
    return await Network.putWithToken<NewFeed>({
      url: '/NewFeed',
      data: data,
    })
  }
}

export class PostNewFeedSevice {
  static async postutNewFeed(data: FormData): Promise<AxiosResponse<NewFeed>> {
    return await Network.postWithToken<NewFeed>({
      url: '/NewFeed',
      data: data,
    })
  }
}
