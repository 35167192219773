import { AxiosResponse } from 'axios'
import { Contact } from '../../Models/GameSource/Contact'
import { Network } from '../network'

export class ContactSevice {
  static async getContactService(): Promise<AxiosResponse<Contact[]>> {
    return await Network.getWithToken<Contact[]>({
      url: '/contact',
    })
  }
}

export class PostContactSevice {
  static async postContactService(
    data: FormData,
  ): Promise<AxiosResponse<Contact>> {
    return await Network.postWithToken<Contact>({
      url: '/contact',
      data: data,
    })
  }
}

export class PutContactSevice {
  static async putContactService(
    data: FormData,
  ): Promise<AxiosResponse<Contact>> {
    return await Network.putWithToken<Contact>({
      url: '/contact',
      data: data,
    })
  }
}

export class DeleteContactSevice {
  static async deleteContactService(
    id: string,
  ): Promise<AxiosResponse<Contact>> {
    return await Network.deleteWithToken<Contact>({
      url: '/contact/' + id,
    })
  }
}
