import { Button, Result } from "antd";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
const Page404 = () => (
  <Result
    status="404"
    title="404"
    subTitle="Désolé, la page n'est pas trouvée."
    extra={
      <Button href="/" type="link">
        Retourner à l'accueil
      </Button>
    }
  />
);

export default Page404;
