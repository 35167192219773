export const BASE_URL =
  process.env.REACT_APP_API_URL || "https://api.techcare.studio";
export const MAX_File_Size = 5000000;

//resize image
export const playerAvatarSize = 256;
export const messageAvatarSize = 128;
export const attachImageWidth = 1920;
export const attachImageHeight = 1280;
export const REF_STATUS = [
  { code: 1, label: "Activé" },
  { code: 2, label: "Désactivé" },

  {
    code: 3,
    label: "Archivé",
  },
  {
    code: 4,
    label: "Téléchargé",
  },
];
export const REF_PROFIL_TYPE = [
  {
    code: "research",
    label: "Chercheur",
  },
];
