import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import { Button, Card, message, Modal, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { useEffect, useState } from "react";
import { HeaderContent } from "../components/Index";
import { UpsertNewFeed, ViewNewFeed } from "../modals";
import { NewFeed } from "../Models/GameSource/NewFeed";
import { url } from "../Models/urls";
import { Network } from "../services";
import {
  DeleteNewFeed,
  NewFeedSevice,
} from "../services/NewFeed/NewFeedSevice";

interface Props {
  npcId?: string;
}

export const NewFeedPixigram = (props: Props) => {
  const [newFeeds, setNewFeeds] = useState<NewFeed[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleView, setIsVisibleView] = useState(false);
  const [newfeed, setNewfeed] = useState<NewFeed>();

  const { npcId } = props;
  useEffect(() => {
    getNewFeeds();
  }, [npcId, isVisible]);

  const getNewFeeds = async () => {
    const res = await NewFeedSevice.getNewFeedService();
    const items: NewFeed[] = [];

    if (Network.isSuccess(res.status)) {
      res.data.forEach((item) => {
        if (item.npcId === npcId) {
          items.push(item);
        }
      });

      setNewFeeds(items);
    }
  };

  const closeModal = () => {
    setIsVisible(false);
  };

  const closeModalView = () => {
    setIsVisibleView(false);
  };

  const handleDeleteNewFeed = async (id: string) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Confirmez-vous la suppression du fil d'actualité ?",
      okText: "Supprimer",
      cancelText: "Annuler",
      onOk: async () => {
        try {
          await DeleteNewFeed.deleteNewFeed(id).then(() => {
            message.success("Suppression avec succès!");
            getNewFeeds();
          });
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  const handleShowModalView = (item: NewFeed) => {
    setNewfeed(item);
    setIsVisibleView(true);
  };

  const handleEditNewFeed = (item: NewFeed) => {
    setNewfeed(item);
    setIsVisible(true);
  };

  const showModalAddNewFeed = (item?: NewFeed) => {
    setNewfeed(item);
    setIsVisible(true);
  };

  return (
    <>
      <HeaderContent
        title="Fils d'actualités"
        button={
          <Button
            type="primary"
            icon={<WechatOutlined style={{ fontSize: 20 }} />}
            key="New Feed Pixigram"
            onClick={() => showModalAddNewFeed()}
          >
            Nouveau fil d'actualité
          </Button>
        }
      />
      <div className="content">
        <Row>
          {newFeeds.map((item) => (
            <Card
              actions={[
                <Button
                  type="text"
                  key="btnView"
                  icon={<EyeOutlined />}
                  onClick={() => handleShowModalView(item)}
                ></Button>,
                <Button
                  type="text"
                  key="btnEdit"
                  onClick={() => handleEditNewFeed(item)}
                  icon={<EditOutlined />}
                ></Button>,
                <Button
                  type="text"
                  key="btnDelete"
                  onClick={() => handleDeleteNewFeed(item._id)}
                  icon={<DeleteOutlined />}
                ></Button>,
              ]}
              key={item._id}
              style={{ width: 250, margin: 10 }}
              cover={
                <img
                  key={item.image.indexOf(item.image[0])}
                  src={
                    item.image
                      ? url + item.image[0]
                      : "https://img.bfmtv.com/c/630/420/871/7b9f41477da5f240b24bd67216dd7.jpg"
                  }
                  style={{
                    height: 200,
                    objectFit: "cover",
                  }}
                />
              }
            >
              <Meta
                title={item.caption}
                description={<p>{item.comment.length} commentaire(s)</p>}
              ></Meta>
            </Card>
          ))}
        </Row>
      </div>
      <UpsertNewFeed
        isVisible={isVisible}
        closeModal={closeModal}
        getNewFeeds={getNewFeeds}
        newfeed={newfeed}
        npcId={npcId}
      />
      <ViewNewFeed
        closeModal={closeModalView}
        isVisible={isVisibleView}
        newfeed={newfeed}
        npcId={npcId}
      />
    </>
  );
};
