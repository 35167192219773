import React from "react";
import "antd/dist/antd.css";
import { Button, Result } from "antd";

export default function ErrorAppFallback() {
  return (
    <Result
      status="500"
      title="500"
      subTitle=" Désolé, une erreur s'est produite."
      extra={
        <Button href="/" type="link">
          Retourner à l'accueil
        </Button>
      }
    />
  );
}
