import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, message, Select } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { HeaderContent } from "../../components/HeaderContent";
import { BASE_URL } from "../../utils/Constants";
import axios from "axios";
import { Export } from "../../Models/GameSource/Export";
import { generateCode } from "../../utils/numberToString";
import { RootState } from "../../store/reducers/rootReducer";
import { useSelector } from "react-redux";
import moment from "moment";
import { AppCode } from "../../Models/GameSource/AppCode";
import { CodeTracking } from "../../Models/GameSource/CodeTracking";

import TrackingGrid from "./TrackingGrid";

export default function TrackingPage() {
  const [tracks, settracks] = useState<any[]>([]);
  const [dataTrack, setdataTrack] = useState<any[]>([]);
  useEffect(() => {
    initTracks();
  }, []);

  const initTracks = async () => {
    try {
      const res: any = await axios.get(BASE_URL + "/tracking");

      if (res && res.data) {
        const tracksTransformed = res.data.sort((a: any, b: any) => {
          return new Date(b.session).getTime() - new Date(a.session).getTime();
        });
        setdataTrack(tracksTransformed);
        let arr: CodeTracking[] = [];
        tracksTransformed.forEach((it: any) => {
          const existCode: any = arr.find((code: CodeTracking) => {
            return code.appcode?._id === it.appcode._id;
          });
          if (existCode) {
            const existSession = existCode.sessions.find(
              (s: any) => s === it.session
            );
            if (!existSession && it.session) {
              existCode.sessions.push(it.session);
            }
          } else {
            const newCode: any = {};
            newCode.appcode = { ...it.appcode };
            newCode.status = it.appcode.disabled;
            newCode.key = it.appcode._id;
            newCode.sessions = [it.session];
            arr.push(newCode);
          }
        });

        settracks(arr);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div>
        <HeaderContent title="Suivie des comportements d'utilisateurs" />
        {tracks && tracks.length > 0 && (
          <div className="content">
            <TrackingGrid
              dataTrack={dataTrack}
              tracks={tracks}
              initTracks={initTracks}
            />
          </div>
        )}
      </div>
    </>
  );
}
