import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/reducers/rootReducer";
import { Navigate, useLocation } from "react-router-dom";
import { Account } from "../Models";

interface Props {
  children?: any;
}
// ----------------------------------------------------------------------
export default function RequireGuest(props: Props) {
  const { isAuthenticated, user, error } = useSelector(
    (state: RootState) => state.auth
  );
  const location = useLocation();
  const toDashboard = user && user.profile ? `/${user.profile}` : "/waiting";
  return isAuthenticated === false ? (
    props.children
  ) : (
    <Navigate to={toDashboard} replace state={{ path: location.pathname }} />
  );
}
