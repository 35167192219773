import { Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { NewPost } from "../Models";
import { url } from "../Models/urls";
import {
  PostNewPostSevice,
  PutNewPostSevice,
} from "../services/newPost/NewPostService";
import { acceptedImages } from "../utils/AccepedImage";
import { MAX_File_Size, playerAvatarSize } from "../utils/Constants";

interface Props {
  visible: boolean;
  onCancel: () => void;
  profileId?: string;
  getNewPost: () => void;
  newPost?: NewPost;
}

export const UpsertStory = (props: Props) => {
  const { visible, onCancel, profileId, getNewPost, newPost } = props;
  const [loadding, setLoadding] = useState(false);
  const [form] = useForm();
  const [imgUrl, setImgUrl] = useState("");
  const [fileImage, setFileImage] = useState<any>();

  useEffect(() => {
    if (newPost && visible) {
      form.setFieldsValue({
        text: newPost.text,
      });
      setImgUrl(url + newPost.image);
    }
  }, [newPost, visible]);

  const handlePostStory = async (values: any) => {
    setLoadding(true);
    const data = new FormData();

    data.append("text", values.text);

    if (fileImage) {
      data.append("file", fileImage);
    } else {
      data.append("image", imgUrl.split(url)[1]);
    }

    if (newPost) {
      data.append("_id", newPost._id);
    }

    profileId && data.append("profileId", profileId);
    try {
      if (newPost) {
        await PutNewPostSevice.putNewPost(data);
      } else {
        await PostNewPostSevice.postutNewPost(data);
      }
      console.log("Done");
      getNewPost();
      closeModal();

      setLoadding(false);
    } catch (error) {
      console.log(error);
      setLoadding(false);
    }
  };

  const closeModal = () => {
    form.resetFields();
    setImgUrl("");
    setFileImage(undefined);
    onCancel();
  };

  const handChangeImg = async (files: any) => {
    const imgLink = URL.createObjectURL(files[0]);
    setImgUrl(imgLink);
    if (!acceptedImages.includes(files[0].type)) {
      alert("Seuls les formats d'image sont acceptés");
    } else {
      if (files[0].size < MAX_File_Size) {
        const img = new Image();
        img.src = URL.createObjectURL(files[0]);
        img.onload = function () {
          const canvas = document.createElement("canvas");
          canvas.setAttribute("style", "object-fit: cover");
          canvas.width = playerAvatarSize;
          canvas.height = playerAvatarSize;
          let ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, playerAvatarSize, playerAvatarSize);
          canvas.toBlob(
            function (blob) {
              if (blob) {
                const newImage = new File(
                  [blob],
                  files[0].name.split(".")[0] + ".jpeg"
                );
                setFileImage(newImage);
              }
            },
            "image/jpeg",
            0.8
          );
        };
      } else {
        Modal.confirm({
          title: "Attention",
          content: "La taille maximum du fichier d'upload est de 5MB",
        });
      }
    }
  };
  return (
    <Modal
      forceRender
      title={newPost ? "Modification" : "Nouvelle"}
      visible={visible}
      onCancel={() => closeModal()}
      footer={null}
    >
      <Form
        name="storyForm"
        layout="vertical"
        form={form}
        onFinish={handlePostStory}
      >
        <div className="text-center">
          <label htmlFor="imgInput" style={{ width: "100%" }}>
            <img
              src={
                imgUrl
                  ? imgUrl
                  : "https://img.icons8.com/ios-filled/150/e0e0e0/stack-of-photos--v1.png"
              }
              style={{ width: "50%", height: "auto", objectFit: "cover" }}
            />
            <p className="text-muted" style={{ marginTop: 10 }}>
              Sélectionner une image
            </p>
          </label>
        </div>
        <Form.Item name="image">
          <Input
            accept={acceptedImages.toString()}
            id="imgInput"
            placeholder=""
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handChangeImg(e.target.files)}
          />
        </Form.Item>
        <Form.Item name="text" label="Contenu du story">
          <Input placeholder="story..." />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" loading={loadding}>
            Valider
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
