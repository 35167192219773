import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { useEffect, useState } from "react";
import { Profile, Contact } from "../Models";
import { url } from "../Models/urls";
import { acceptedImages } from "../utils/AccepedImage";
import {
  PostProfileSevice,
  PutProfileSevice,
} from "../services/Profile/ProfileSevice";
import { MAX_File_Size, messageAvatarSize } from "../utils/Constants";
import { ContactSevice } from "../services/Contacts/ContactSevice";
import { Network } from "../services";

interface Props {
  visible: boolean;
  onCancel: () => void;
  getProfile: () => void;
  profile?: Profile | undefined;
  isEditInfo?: boolean;
}
const { Option } = Select;
export const UpsertProfile = (props: Props) => {
  const { visible, onCancel, getProfile, profile, isEditInfo } = props;
  const [avatar, setAvatar] = useState<any>();
  const [images, setImages] = useState<UploadFile[]>([]);
  const [loadding, setLoadding] = useState(false);
  const [boldAvatar, setBoldAvatar] = useState("");
  const [form] = Form.useForm();
  const [npcs, setNpcs] = useState<Contact[]>([]);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        npcId: profile?.npcId,
        profileName: profile?.profileName,
        star: profile?.info[0],
        following: profile?.info[1],
        follower: profile?.info[2],
        status: profile?.status,
      });
      setBoldAvatar(url + profile.avatar);
      setAvatar(profile.avatar);
    }
  }, [profile, visible]);

  useEffect(() => {
    if (profile) {
      const picture: UploadFile[] = [];
      profile.imageList.forEach((item) => {
        picture.push({
          uid: "img" + profile.imageList.indexOf(item),
          name: "",
          url: url + item,
        });
      });

      setImages(picture);
    }
  }, [visible]);
  useEffect(() => {
    fetchNpc();
  }, []);
  const fetchNpc = async () => {
    try {
      const res = await ContactSevice.getContactService();
      if (Network.isSuccess(res.status)) {
        const data = res.data;
        const NPCs: Contact[] = [];

        data
          .filter((item: Contact) => item.isNPC)
          .forEach((item: Contact) => {
            NPCs.push(item);
          });
        NPCs.push({
          _id: "myId",
          image: "",
          name: "Player",
          phone: "",
          isNPC: false,
        });

        setNpcs(NPCs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePostProfile = async (values: any) => {
    //setLoadding(true)

    const data = new FormData();

    //image
    if (avatar) {
      data.append("avatar", avatar);
    } else {
      data.append("avatar", JSON.stringify(profile?.avatar));
    }

    data.append("npcId", values.npcId);
    /*  data.append("profileName", values.profileName); */
    data.append("status", values.status);

    const info: string[] = [];
    info.push(values.star);
    info.push(values.following);
    info.push(values.follower);

    data.append("info", JSON.stringify(info));

    if (values.images) {
      const files = values.images.fileList;
      files.forEach((item: any) => {
        if (item.url) {
          data.append("imageList", "/" + item.url.split(url)[1]);
          //console.log(item.url.split(url)[1])
        } else {
          data.append("files", item.originFileObj);
        }
      });
    } else {
      profile?.imageList.forEach((item) => {
        data.append("imageList", item);
      });
    }

    if (profile) {
      data.append("profileId", profile._id);
    }
    try {
      let res: any = {};
      if (profile) {
        res = await PutProfileSevice.putProfileService(data);
      } else {
        res = await PostProfileSevice.postProfileService(data);
      }
      // console.log('Done')
      getProfile();
      setLoadding(false);
      closeModal();
    } catch (error: any) {
      console.log(error);
      const msg: string = error.response.data.message;
      Modal.error({
        title: "Erreur",
        content: msg,
        okText: "OK",
      });
    }
  };

  const closeModal = () => {
    form.resetFields();
    onCancel();
    setAvatar("");
    setBoldAvatar("");
    setImages([]);
  };

  const handleChangeAvatar = (files: any) => {
    const urlAvatar = URL.createObjectURL(files[0]);
    setBoldAvatar(urlAvatar);
    if (files && !acceptedImages.includes(files[0].type)) {
      Modal.error({
        title: "Attention",
        content: "Seuls les formats d'image sont acceptés",
        okText: "OK",
      });
    } else {
      if (files[0].size < MAX_File_Size) {
        //resize at here
        const img = new Image();
        img.src = URL.createObjectURL(files[0]);
        img.onload = function () {
          const canvas = document.createElement("canvas");
          //get width and height for calc ratio
          let width = img.width;
          let height = img.height;
          const ratio = messageAvatarSize / width;
          //canvas.setAttribute('style', 'object-fit: cover')
          canvas.width = width * ratio;
          canvas.height = height * ratio;
          let ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, width * ratio, height * ratio);
          canvas.toBlob(
            function (blob) {
              if (blob) {
                const newImage = new File(
                  [blob],
                  files[0].name.split(".")[0] + ".jpeg"
                );
                setAvatar(newImage);
              }
            },
            "image/jpeg",
            0.8
          );
        };
      } else {
        Modal.confirm({
          title: "Attention",
          content: "La taille maximum du fichier d'upload est de 5MB",
        });
      }
    }
  };

  return (
    <Modal
      forceRender
      width={768}
      visible={visible}
      footer={null}
      onCancel={() => closeModal()}
      title={profile ? "Modifier" : "Nouveau"}
    >
      <Form form={form} layout="vertical" onFinish={handlePostProfile}>
        <Row justify="center">
          <Col className="text-center">
            <label htmlFor="inpAvatar">
              <img
                style={{
                  width: 150,
                  height: 150,
                  borderRadius: "50%",
                  border: "1px solid #e0e0e0",
                }}
                src={
                  boldAvatar
                    ? boldAvatar
                    : "https://png.pngtree.com/png-vector/20191129/ourmid/pngtree-image-upload-icon-photo-upload-icon-png-image_2047546.jpg"
                }
                alt="Sélectionner un avatar"
              />
              <p>
                <i>Sélectionner un avatar</i>
              </p>
            </label>

            <Input
              accept={acceptedImages.toString()}
              disabled={profile && !isEditInfo ? true : false}
              onChange={(a) => handleChangeAvatar(a.target.files)}
              type="file"
              style={{ display: "none" }}
              id="inpAvatar"
            />
          </Col>
        </Row>
        <Form.Item
          name="npcId"
          label="Peronnage du jeu"
          rules={[
            {
              required: true,
              message: "sélectionner une personnage",
            },
          ]}
        >
          <Select>
            {npcs &&
              npcs.map((npc) => (
                <Option key={npc._id} value={npc._id}>
                  {npc.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {/*  <Form.Item
          name="profileName"
          label="Nom du profil"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le nom du profil",
            },
          ]}
        >
          <Input
            placeholder="nom du profil..."
            disabled={profile && !isEditInfo ? true : false}
          />
        </Form.Item> */}
        <Row gutter={[10, 0]}>
          <Col flex={1}>
            <Form.Item name="star" label="Star" initialValue={0}>
              <Input
                style={{ width: "100%" }}
                maxLength={9}
                showCount
                type="text"
                placeholder="star"
                disabled={profile && !isEditInfo ? true : false}
              />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name="following" label="Following" initialValue={0}>
              <Input
                style={{ width: "100%" }}
                maxLength={9}
                showCount
                type="text"
                placeholder="following"
                disabled={profile && !isEditInfo ? true : false}
              />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name="follower" label="Follower" initialValue={0}>
              <Input
                style={{ width: "100%" }}
                maxLength={9}
                showCount
                type="text"
                placeholder="follower"
                disabled={profile && !isEditInfo ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="status" label="Statut">
          <Input.TextArea
            placeholder="statut..."
            rows={5}
            disabled={profile && !isEditInfo ? true : false}
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={loadding}
            type="primary"
            onClick={() => form.submit()}
          >
            {profile ? "Modifier" : "Valider"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
