import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RootState } from "../store/reducers/rootReducer";

import { useSelector } from "react-redux";

// ----------------------------------------------------------------------
interface Props {
  children?: any;
}

export default function AuthWaitingToRedirect(props: Props) {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) return;
    if (!user.profile) return;
    navigate(`/${user.profile}`);
  }, [user]);

  return <></>;
}
