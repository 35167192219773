import {
  DeleteOutlined,
  EditFilled,
  ExclamationCircleOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import { Affix, Alert, Avatar, Button, Card, List, message, Modal } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Contact, Message } from "../Models";
import { url } from "../Models/urls";
import { styles } from "../styles/styles";
import { HeaderContent } from "../components/HeaderContent";
import { UpserMessageModal } from "../modals";
import { ContactSevice } from "../services/Contacts/ContactSevice";
import { Network } from "../services";
import {
  DeleteMessageService,
  MessageService,
} from "../services/Message/MessageSevice";
import { CONST_SCREENS } from "../utils/const-screens";

export const ScreenDetail = () => {
  const { id, name } = useParams();

  const [isVisibleModalAddMessage, setIsVisibleModalAddMessage] =
    useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [mes, setMes] = useState<Message | undefined>();
  const [npcs, setNpcs] = useState<Contact[]>([]);

  const [isWarning, setIsWarning] = useState(false);

  useEffect(() => {
    id && fetchMessages(id);
  }, []);

  useEffect(() => {
    fetchNpc();
  }, [id]);

  const fetchNpc = async () => {
    try {
      const res = await ContactSevice.getContactService();
      if (Network.isSuccess(res.status)) {
        const data = res.data;
        const NPCs: Contact[] = [];

        data.forEach((item: Contact) => {
          if (item.isNPC === true) {
            NPCs.push(item);
          }
        });

        NPCs.push({
          _id: "myId",
          image: "",
          name: "Player",
          phone: "",
          isNPC: false,
        });

        setNpcs(NPCs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNpc = (id: string) => {
    let name = "";

    if (id === "myId") {
      name = "Player";
    } else {
      npcs.forEach((item) => {
        if (id === item._id) {
          name = item.name;
        }
      });
    }

    return name;
  };

  const showModalAddMessage = () => {
    setIsVisibleModalAddMessage(true);
  };

  const closeModal = () => {
    setIsVisibleModalAddMessage(false);
    setMes(undefined);
  };

  const fetchMessages = async (id: string) => {
    try {
      const res: any = await MessageService.getMessageService(id);

      if (Network.isSuccess(res.status)) {
        setMessages(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //get list npc to select and list
  const delMessage = async (idItem: string) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Confirmez vous la suppression",
      okText: "Supprimer",
      cancelText: "Annuler",
      onOk: async () => {
        await DeleteMessageService.deleteMessageService(idItem).then(() => {
          id && fetchMessages(id);
          message.success("Suppression avec succès!");
        });
      },
    });
  };

  const handleEditMessage = (value: Message) => {
    setMes(value);
    setIsVisibleModalAddMessage(true);
  };

  const showOptions = (opt: string) => {
    const strArray: string[] = opt.split("##");
    return (
      <>
        <p style={{ color: "#212121" }}>Réponse: {strArray[0]}</p>
        <p style={{ color: "#212121" }}>Aider: {strArray[1]}</p>
      </>
    );
  };

  useEffect(() => {
    messages.forEach((item) => {
      item.isUnlockStep && setIsWarning(false);
    });
  }, [isWarning, isVisibleModalAddMessage]);
  const getActions = (message: Message): any[] => {
    try {
      const npcFounded = getNpc(message.npcId);
      if (npcFounded) {
        return [
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => handleEditMessage(message)}
          ></Button>,
        ];
      }

      return [
        <Button
          type="text"
          icon={<EditFilled />}
          onClick={() => handleEditMessage(message)}
        ></Button>,
        <Button
          type="primary"
          danger
          icon={<DeleteOutlined />}
          onClick={() => delMessage(message._id)}
        ></Button>,
      ];
    } catch (error) {
      return [
        <Button
          type="text"
          icon={<EditFilled />}
          onClick={() => handleEditMessage(message)}
        ></Button>,
      ];
    }
  };
  return (
    <Content style={styles.bodyComponent}>
      <Affix offsetTop={0}>
        <HeaderContent
          title={"Message / " + name}
          button={
            id && !CONST_SCREENS.includes(id) ? (
              <Button
                type="primary"
                icon={<WechatOutlined style={{ fontSize: 20 }} />}
                onClick={() => showModalAddMessage()}
                key="message"
              >
                Add new message
              </Button>
            ) : null
          }
        />
      </Affix>
      <Card style={styles.card}>
        {
          //only check this screen
          id === "6296d3c49bf60eb57f674708" && isWarning ? (
            <Alert
              message="Attention"
              description="Veuillez sélectionner au moin une image pour débloquer la suite de cette étape"
              type="warning"
              showIcon
            />
          ) : null
        }

        <List>
          {messages.map((message) => (
            <List.Item key={message._id} actions={getActions(message)}>
              <List.Item.Meta
                avatar={
                  message.image.length > 0 ? (
                    <Avatar
                      style={{ width: 100, height: 100, borderRadius: 5 }}
                      src={url + message.image}
                    />
                  ) : null
                }
                title={
                  message.npcId === "myId"
                    ? "Joueur"
                    : "Expéditeur: " + getNpc(message.npcId)
                }
                description={
                  <label htmlFor={message._id}>
                    <>
                      {message.npcId !== "myId" ? (
                        <>
                          <p
                            style={{ whiteSpace: "pre-line", color: "#212121" }}
                          >
                            Message: {message.text}
                          </p>
                          {id === "6296d3c49bf60eb57f674708" &&
                          message.isUnlockStep ? (
                            <p
                              style={{
                                whiteSpace: "pre-line",
                                color: "#212121",
                              }}
                            >
                              <i className="fas fa-check-circle text-danger"></i>{" "}
                              Image utilisée pour débloquer l'étape de recherche
                              du laboratoire
                            </p>
                          ) : null}
                        </>
                      ) : (
                        //Salut ! Oui, dis-moi en plus##Salut ! Je n’ai pas compris
                        <>
                          {showOptions(message.text)}
                          {message.helpContent && (
                            <p
                              style={{
                                whiteSpace: "pre-line",
                                color: "#212121",
                              }}
                            >
                              Contenu d'aide: {message.helpContent}
                            </p>
                          )}
                        </>
                      )}
                      {message.date
                        ? "Date: " + message.date.split("T")[0]
                        : null}
                    </>
                  </label>
                }
              />
            </List.Item>
          ))}
        </List>
      </Card>

      <UpserMessageModal
        mes={mes}
        isVisible={isVisibleModalAddMessage}
        closeModal={closeModal}
        getMessage={() => id && fetchMessages(id)}
        npcs={npcs}
        screenId={id}
      />
    </Content>
  );
};
