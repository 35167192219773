import { AxiosResponse } from 'axios'
import { NewPost } from '../../Models'
import { Network } from '../network'

export class NewPostService {
  static async getNewPost(id: string): Promise<AxiosResponse<NewPost[]>> {
    return await Network.getWithToken<NewPost[]>({
      url: '/newPost',
      params: { profileId: id },
    })
  }
}

export class DeleteNewPostService {
  static async deleteNewPost(id: string): Promise<AxiosResponse<NewPost>> {
    return await Network.deleteWithToken<NewPost>({
      url: '/newPost/' + id,
    })
  }
}

export class PutNewPostSevice {
  static async putNewPost(data: FormData): Promise<AxiosResponse<NewPost>> {
    return await Network.putWithToken<NewPost>({
      url: '/newPost',
      data: data,
    })
  }
}

export class PostNewPostSevice {
  static async postutNewPost(data: FormData): Promise<AxiosResponse<NewPost>> {
    return await Network.postWithToken<NewPost>({
      url: '/newPost',
      data: data,
    })
  }
}
