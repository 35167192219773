export const DashboardRech = () => {
  
  return (
    <div>
      <div className="container" style={{ marginTop: 30 }}>
        <h3 style={{ fontWeight: "bold", color: "#7f8c8d", marginBottom: 0 }}>
          Bienvenu!
        </h3>
        <h1
          style={{
            fontWeight: "bold",
            color: "#2c3e50",
            fontSize: 68,
            marginBottom: 20,
          }}
        >
          Espace de recherche
        </h1>
        <p style={{ fontSize: 22 }} className="text-muted">
          Gestion du contenu de l'application mobile Techcare
        </p>
      </div>
    </div>
  );
};
