import React from "react";
import {
  Tabs,
  Image,
  Collapse,
  List,
  Card,
  Avatar,
  Row,
  Col,
  Divider,
} from "antd";
import { Export } from "../../Models/GameSource/Export";
import { CheckCircleOutlined } from "@ant-design/icons";
import { getByTitle } from "@testing-library/react";
import { DefaultImage } from "../../utils/defaultImage";
import { BASE_URL } from "../../utils/Constants";
import { styles } from "../../styles/styles";
interface Props {
  editExport?: Export;
}
export default function ExportContent(props: Props) {
  const { editExport } = props;
  const content = editExport?.content;
  if (!editExport || !content) return <></>;
  return (
    <Tabs defaultActiveKey="1" tabPosition="right">
      <Tabs.TabPane tab="Avatars" key="1">
        <Avatars {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Groupe chat" key="2">
        <GroupChat {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Messages" key="3">
        <Messages {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Contacts" key="4">
        <Contacts {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Notes" key="5">
        <Notes {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Map" key="6">
        <Map {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Pixagram" key="7">
        <Pixagram {...props} />
      </Tabs.TabPane>
    </Tabs>
  );
}
const Avatars = (props: Props) => {
  const { editExport } = props;
  const content = editExport?.content;
  if (!content) return <></>;
  const contentParsed: any = JSON.parse(content);

  return (
    <>
      {Array.isArray(contentParsed.avatar) && contentParsed.avatar.length > 0 && (
        <>
          {contentParsed.avatar.map((it: string, i: number) => {
            return <Image key={i} width={200} src={it} />;
          })}
        </>
      )}
    </>
  );
};
const { Panel } = Collapse;
const GroupChat = (props: Props) => {
  const { editExport } = props;
  const content = editExport?.content;
  if (!content) return <></>;
  const contentParsed: any = JSON.parse(content);

  const getContentOptions = (item: any) => {
    try {
      const options = item.content.split("##");
      const helpContent = item.helpContent;

      return (
        <List.Item style={{ display: "flex", flexDirection: "column" }}>
          <List.Item.Meta
            style={{ width: "100%" }}
            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
            title={<a href="#">{item.senderName}</a>}
            description={helpContent ? `Aide: ${item.helpContent}` : ""}
          />

          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              minHeight: "30px",
            }}
          >
            {options[0] && (
              <>
                <CheckCircleOutlined
                  style={{ marginLeft: "30px", marginRight: "10px" }}
                />{" "}
                {options[0]}
              </>
            )}
            {options[1] && (
              <>
                <CheckCircleOutlined
                  style={{ marginLeft: "30px", marginRight: "10px" }}
                />
                {options[1]}
              </>
            )}
          </div>
        </List.Item>
      );
    } catch (error) {
      console.error(error);
      return <></>;
    }
  };
  return (
    <>
      {Array.isArray(contentParsed.detectiveMsg) &&
        contentParsed.detectiveMsg.length > 0 && (
          <>
            <Collapse defaultActiveKey={["1"]}>
              {contentParsed.detectiveMsg.map((it: string, i: number) => {
                const keyText = Object.keys(it)[0];
                const value = Object.values(it)[0];
                return (
                  <Panel header={keyText} key={i + 1}>
                    {Array.isArray(value) && (
                      <>
                        <List
                          style={{ width: "100%" }}
                          itemLayout="horizontal"
                          dataSource={value}
                          renderItem={(item) => (
                            <>
                              {item.type === "friendId" ? (
                                <List.Item
                                  extra={
                                    <>
                                      {item.imageUrl ? (
                                        <img
                                          width={272}
                                          alt="image"
                                          src={item.imageUrl}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  }
                                >
                                  <List.Item.Meta
                                    style={{ width: "100%" }}
                                    avatar={<Avatar src={item.avatarUrl} />}
                                    title={<a href="#">{item.senderName}</a>}
                                  />
                                  {item.content}
                                </List.Item>
                              ) : item.type === "message-options" ? (
                                <>{getContentOptions(item)}</>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        />
                      </>
                    )}
                  </Panel>
                );
              })}
            </Collapse>
          </>
        )}
    </>
  );
};
const Messages = (props: Props) => {
  const { editExport } = props;
  const content = editExport?.content;
  if (!content) return <></>;
  const contentParsed: any = JSON.parse(content);

  const getSender = (val: string) => {
    try {
      const valSplit = val.split("@");
      return valSplit[valSplit.length - 1];
    } catch (error) {
      return "";
    }
  };
  return (
    <>
      {Array.isArray(contentParsed.leoMsg) && contentParsed.leoMsg.length > 0 && (
        <>
          <Collapse defaultActiveKey={["1"]}>
            {contentParsed.leoMsg.map((it: string, i: number) => {
              const keyText = Object.keys(it)[0];
              const value = Object.values(it)[0];
              return (
                <Panel header={getSender(keyText)} key={i + 1}>
                  {Array.isArray(value) && (
                    <>
                      <List
                        style={{ width: "100%" }}
                        itemLayout="horizontal"
                        dataSource={value}
                        renderItem={(item) => (
                          <List.Item
                            extra={
                              <>
                                {item.imageUrl ? (
                                  <img
                                    width={272}
                                    alt="image"
                                    src={item.imageUrl}
                                  />
                                ) : (
                                  <></>
                                )}
                              </>
                            }
                          >
                            <List.Item.Meta
                              style={{ width: "100%" }}
                              avatar={<Avatar src={item.avatarUrl} />}
                              title={<a href="#">{item.senderName}</a>}
                            />
                            {item.content}
                          </List.Item>
                        )}
                      />
                    </>
                  )}{" "}
                </Panel>
              );
            })}
          </Collapse>
        </>
      )}
    </>
  );
};
const Contacts = (props: Props) => {
  const { editExport } = props;
  const content = editExport?.content;
  if (!content) return <></>;
  const contentParsed: any = JSON.parse(content);
  const getItem = (item: any) => {
    try {
      const itemSplit = item.split("##");
      const name = itemSplit[itemSplit.length - 2];
      const num = itemSplit[itemSplit.length - 1];
      return `${name} - ${num}`;
    } catch (error) {
      return "";
    }
  };
  return (
    <>
      {Array.isArray(contentParsed.contact) &&
        contentParsed.contact.length > 0 && (
          <>
            <List
              size="small"
              header={<div>Les contacts</div>}
              bordered
              dataSource={contentParsed.contact}
              renderItem={(item) => <List.Item>{getItem(item)}</List.Item>}
            />
          </>
        )}
    </>
  );
};
const Notes = (props: Props) => {
  const { editExport } = props;
  const content = editExport?.content;
  if (!content) return <></>;
  const contentParsed: any = JSON.parse(content);
  const getContent = (value: any, type: string) => {
    try {
      const newArr = value.slice(1);
      return newArr.join("<br/>");
    } catch (error) {
      console.log(error);
      return "";
    }
  };
  if (!contentParsed.listNote) return <></>;
  const listNote = Object.values(contentParsed.listNote);
  return (
    <>
      {contentParsed.listNote && (
        <>
          <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={listNote}
            renderItem={(item: any) => {
              const keyText = item[0];
              console.log(keyText);
              return (
                <List.Item>
                  <Card title={keyText}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getContent(item, "content"),
                      }}
                    />
                  </Card>
                </List.Item>
              );
            }}
          />
        </>
      )}
    </>
  );
};
const { Meta } = Card;
const Map = (props: Props) => {
  const { editExport } = props;
  const content = editExport?.content;
  if (!content) return <></>;
  const contentParsed: any = JSON.parse(content);

  return (
    <Row style={{ marginTop: 20 }}>
      {Array.isArray(contentParsed.location) &&
        contentParsed.location.length > 0 && (
          <>
            {contentParsed.location.map((location: any, i: number) => {
              const locationSplit = location.split("##");

              const image = locationSplit[0];
              const title = locationSplit[1];
              const content = locationSplit[2];
              console.log(image, title, content);
              return (
                <>
                  {location.includes("##") && (
                    <Card
                      key={i}
                      style={{ width: 300, margin: 5 }}
                      cover={
                        <img
                          style={{
                            width: "100%",
                            height: 200,
                            objectFit: "cover",
                          }}
                          alt={title}
                          src={image ? image : DefaultImage}
                        />
                      }
                    >
                      <Meta
                        style={{ maxHeight: 200 }}
                        title={title}
                        description={content}
                        className="card-description"
                      />
                    </Card>
                  )}
                </>
              );
            })}
          </>
        )}
    </Row>
  );
};
const Pixagram = (props: Props) => {
  const { editExport } = props;
  const content = editExport?.content;
  if (!content) return <></>;
  const contentParsed: any = JSON.parse(content);
  const getValueField = (value: any, field: string) => {
    try {
      return value[field];
    } catch (error) {
      return "";
    }
  };
  return (
    <>
      {Array.isArray(contentParsed.pixagram) &&
        contentParsed.pixagram.length > 0 && (
          <>
            <Collapse defaultActiveKey={["1"]}>
              {contentParsed.pixagram.map((it: string, i: number) => {
                const keyText = Object.keys(it)[0];
                const value = Object.values(it)[0];
                const info = getValueField(value, "info");
                const avatar = getValueField(value, "avatar");
                const nickName = getValueField(value, "nickName");
                const status = getValueField(value, "status");
                const newPosts = getValueField(value, "newPosts");
                const feeds = getValueField(value, "imageList");
                return (
                  <Panel header={nickName} key={i + 1}>
                    {value && (
                      <>
                        <List.Item
                          actions={
                            info
                              ? [
                                  <p key="1">Star {info[0]}</p>,
                                  <p key="2">Following {info[1]}</p>,
                                  <p key="3">Follower {info[2]}</p>,
                                ]
                              : []
                          }
                          style={{ padding: 0 }}
                        >
                          <List.Item.Meta
                            avatar={
                              avatar ? (
                                <Avatar src={BASE_URL + "/" + avatar} />
                              ) : null
                            }
                            title={nickName}
                            description={status}
                          />
                        </List.Item>
                        {/* posts */}
                        {Array.isArray(newPosts) && (
                          <>
                            <Divider orientation="left">Story</Divider>
                            <Row>
                              {newPosts.map((item, i) => {
                                const itemSplit = item.split("##");
                                const image = itemSplit[0];
                                const text = itemSplit[1];
                                return (
                                  <Col
                                    className="newpost-container"
                                    key={i}
                                    style={{
                                      textAlign: "center",
                                      margin: "0 10px",
                                    }}
                                  >
                                    <Image
                                      key={i}
                                      width={80}
                                      style={{
                                        height: 80,
                                        borderRadius: "50%",
                                      }}
                                      src={image}
                                    />
                                    <h6
                                      style={{
                                        textTransform: "capitalize",
                                        width: 150,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {text}
                                    </h6>
                                  </Col>
                                );
                              })}
                            </Row>
                          </>
                        )}
                        {/* feed */}
                        {Array.isArray(feeds) && (
                          <>
                            <Divider orientation="left">
                              Fils d'actualité
                            </Divider>
                            <List
                              style={{ width: "100%" }}
                              itemLayout="horizontal"
                              dataSource={feeds}
                              renderItem={(item) => {
                                const itemSplit = item.split("##");
                                const image = itemSplit[0];
                                const likes = itemSplit[1];
                                const caption = itemSplit[2];
                                const restString = itemSplit[3].split("@@");
                                const feedDate = restString[0];
                                return (
                                  <List.Item
                                    extra={
                                      <>
                                        {image ? (
                                          <img
                                            width={272}
                                            alt="image"
                                            src={image}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    }
                                  >
                                    <List.Item.Meta
                                      style={{ width: "100%" }}
                                      title={<a href="#">{item.caption}</a>}
                                    />
                                    {feedDate}
                                    <PixaDescritpion
                                      caption={caption}
                                      content={restString}
                                    />
                                  </List.Item>
                                );
                              }}
                            />
                          </>
                        )}
                      </>
                    )}
                  </Panel>
                );
              })}
            </Collapse>
          </>
        )}
    </>
  );
};
const PixaDescritpion = (props: any) => {
  const { caption, content } = props;
  const getComments = () => {
    try {
      const commentStr = content[1];
      if (!commentStr || commentStr === "") return 0;
      const contentSplit = commentStr.split("::");
      if (contentSplit.length == 0) return [];
      return contentSplit.map((it: string) => {
        const itSplit = it.split("%%");
        return {
          sender: itSplit[0],
          msg: itSplit[1],
        };
      });
    } catch (error) {
      return [];
    }
  };
  const comments = getComments();
  return (
    <>
      <Meta
        title={caption}
        description={<p>{comments.length} commentaire(s)</p>}
      ></Meta>
      {comments.length > 0 && <PixagramComment comments={comments} />}
    </>
  );
};
const PixagramComment = (props: any) => {
  const { comments } = props;
  if (!comments || comments.length == 0) return <></>;
  return (
    <List>
      {comments.map((item: any, i: number) => (
        <Card
          title={
            <Row>
              <Col flex={1}>
                <b>{item.sender}</b>
              </Col>
            </Row>
          }
          style={styles.cardInner}
          type="inner"
        >
          <List.Item key={i}>
            <List.Item.Meta description={item.msg} />
          </List.Item>
        </Card>
      ))}
    </List>
  );
};
