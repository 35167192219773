import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Button, Card, Modal, Row, message } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useEffect, useState } from 'react'
import { styles } from '../styles/styles'
import { HeaderContent } from '../components/HeaderContent'
import { url } from '../Models/urls'
import { Local } from '../Models'
import { UpsertLocationModal } from '../modals'
import {
  DeleteLocationSevice,
  LocationSevice,
} from '../services/Location/LocationSevice'
import { Network } from '../services'
import { DefaultImage } from '../utils/defaultImage'

const { Meta } = Card

export const Locations = () => {
  const [locations, setLocations] = useState<Local[]>([])
  const [isVisibleModalUpsert, setIsVisibleModalUpsert] = useState(false)
  const [location, setLocation] = useState<Local>()

  useEffect(() => {
    getLocation()
  }, [])

  const getLocation = async () => {
    const res = await LocationSevice.getLocalService()

    if (Network.isSuccess(res.status)) {
      setLocations(res.data)
    }
  }

  const delLocation = (id: string) => {
    Modal.confirm({
      title: 'Attention',
      icon: <ExclamationCircleOutlined />,
      content: "Confirmez-vous la suppression de l'adresse",
      okText: 'Supprimer',
      cancelText: 'Annuler',
      onOk: async () => {
        try {
          await DeleteLocationSevice.deleteLocalService(id).then(() => {
            message.success('Suppression avec succès!')
            getLocation()
          })
        } catch (error) {
          console.log(error)
        }
      },
    })
  }

  const showUpsertLocationModal = (item?: Local) => {
    setLocation(item)
    setIsVisibleModalUpsert(true)
  }

  const handleEditLocation = (item: Local) => {
    setLocation(item)
    setIsVisibleModalUpsert(true)
  }

  const closeModal = () => {
    setIsVisibleModalUpsert(false)
  }

  return (
    <Content style={styles.bodyComponent}>
      <HeaderContent
        title="Locations"
        button={
          <Button
            type="primary"
            onClick={() => showUpsertLocationModal()}
            key="location"
          >
            <i className="fas fa-location"></i> Nouvelle
          </Button>
        }
      />

      <Row style={{ marginTop: 20 }}>
        {locations.map((location) => (
          <Card
            key={location._id}
            style={{ width: 300, margin: 5 }}
            cover={
              <img
                style={{
                  width: '100%',
                  height: 200,
                  objectFit: 'cover',
                }}
                alt={location.title}
                src={location.image ? url + location.image : DefaultImage}
              />
            }
            actions={[
              <Button
                key="btn1"
                onClick={() => handleEditLocation(location)}
                type="text"
                icon={<EditOutlined />}
              ></Button>,
              <Button
                type="text"
                onClick={() => delLocation(location._id)}
                key="ellipsis"
                icon={<DeleteOutlined />}
              />,
            ]}
          >
            <Meta
              style={{ maxHeight: 200 }}
              title={location.title}
              description={location.content}
              className="card-description"
            />
          </Card>
        ))}
      </Row>

      <UpsertLocationModal
        isVisible={isVisibleModalUpsert}
        location={location}
        closeModal={closeModal}
        getLocations={getLocation}
      />
    </Content>
  )
}
