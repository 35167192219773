import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HeaderContent } from "../../components/HeaderContent";
import { BASE_URL } from "../../utils/Constants";
import axios from "axios";
import { Network } from "../../services";
import { ProfilesSevice } from "../../services/Profile/ProfileSevice";
import { Export } from "../../Models/GameSource/Export";
import FormInfo from "./FormInfo";
import ExportContent from "./ExportContent";
import { RootState } from "../../store/reducers/rootReducer";
import { useSelector } from "react-redux";

export const EditExportPage = () => {
  const { id } = useParams();
  const [editExport, seteditExport] = useState<Export>({});
  const { user } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    if (!id) return;
    init(id);
  }, [id]);

  const init = async (id: string): Promise<void> => {
    try {
      const res: any = await axios.get(`${BASE_URL}/export/${id}`);
      if (res && res.data) {
        seteditExport(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onComplete = async (res: any) => {
    seteditExport(res);
  };
  return (
    <div>
      {editExport && editExport._id && (
        <>
          <HeaderContent title={editExport.code} />
          <div className="content">
            {user && user.profile === "admin" && (
              <FormInfo editExport={editExport} onComplete={onComplete} />
            )}

            <ExportContent editExport={editExport} />
          </div>
        </>
      )}
    </div>
  );
};
