import React from "react";
import { Button, Form, Input, Select, Modal } from "antd";
import { Export } from "../../Models/GameSource/Export";
import { REF_STATUS } from "../../utils/Constants";
import axios from "axios";
import { formatDate } from "../../utils/timeUtil";

import { BASE_URL } from "../../utils/Constants";

interface Props {
  editExport?: Export;
  onComplete?: any;
}
const { Option } = Select;

export default function FormInfo(props: Props) {
  const { editExport, onComplete } = props;
  const onFinish = async (values: any) => {
    try {
      const { name, status } = values;
      const res = await axios.patch(`${BASE_URL}/export`, {
        ...editExport,
        name,
        status,
      });
      if (res && res.data) {
        onComplete(res.data);
        Modal.success({
          title: "Succès",
          content: "Le scénario exporté a été mis à jour.",
          okText: "OK",
        });
      }
    } catch (error: any) {
      console.error(error);
      const msg: string = error.response.data.message;
      Modal.error({
        title: "Erreur",
        content: msg,
        okText: "OK",
      });
    }
  };
  const isDisabled = editExport && editExport?.status === 4 ? true : false;
  return (
    <Form
      name="customized_form_controls"
      layout="inline"
      onFinish={onFinish}
      initialValues={{
        name: editExport ? editExport.name : "",
        status: editExport ? editExport.status : "",
      }}
    >
      <Form.Item
        name="name"
        label="Nom"
        rules={[
          {
            required: true,
            message: "Veuillez renseigner le nom du scénario exporté",
          },
        ]}
      >
        <Input placeholder="nom" disabled={isDisabled} />
      </Form.Item>

      <Form.Item
        label="Statut"
        name="status"
        rules={[{ required: true, message: "Veuillez renseigner le statut" }]}
      >
        <Select placeholder="Sélectionner le statut" disabled={isDisabled}>
          {REF_STATUS.map((it: any) => (
            <Option key={it.code} value={it.code}>
              {it.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Exporté le:" name="executedat">
        {formatDate(editExport?.executedat)}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isDisabled}>
          Valider
        </Button>
      </Form.Item>
    </Form>
  );
}
