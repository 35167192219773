import React, { useEffect, useState } from "react";
import {
  Button,
  Avatar,
  List,
  message,
  Popover,
  Form,
  Select,
  Input,
} from "antd";
import { formatTime } from "../../utils/timeUtil";
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  KeyOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { BASE_URL } from "../../utils/Constants";
import axios from "axios";
import { generateCode } from "../../utils/numberToString";
import ModalUser from "./ModalUser";
export default function UsersGrid(props: any) {
  const { users, initUsers } = props;

  const handleUser = async (type: string, item: any) => {
    try {
      if (item && item._id) {
        await axios.patch(BASE_URL + "/admin-users", {
          ...item,
          disabled: type === "enable" ? false : true,
          disabledat: type === "enable" ? null : new Date(),
        });
        await initUsers();
        message.success("Modification avec succès!");
      }
    } catch (error: any) {
      console.error(error);
      console.log(error);
      let msg = "";
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = "Une erreur est survenue. Veuillez réessayer";
      }
      message.error(msg);
    }
  };
  return (
    <List
      itemLayout="horizontal"
      size="large"
      pagination={{
        onChange: (page) => {
          console.log(page);
        },
        pageSize: 50,
      }}
      dataSource={users}
      renderItem={(item: any) => (
        <List.Item
          key={item.title}
          actions={[
            <>
              {item.disabled ? (
                <></>
              ) : (
                <>
                  <ModalUser initUsers={initUsers} editUser={item} />
                  <UpdatePassword editUser={item} />
                </>
              )}
            </>,
            <>
              {item.disabled ? (
                <Button
                  size="small"
                  type="ghost"
                  onClick={() => handleUser("enable", item)}
                  icon={<PlayCircleOutlined />}
                >
                  Activer
                </Button>
              ) : (
                <Button
                  size="small"
                  type="dashed"
                  danger
                  onClick={() => handleUser("disable", item)}
                  icon={<PauseCircleOutlined />}
                >
                  Désactiver
                </Button>
              )}
            </>,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar src={item.avatar} />}
            title={item.username}
            description={
              <div>
                {`${item.firstname} ${item.lastname}`}
                <br />
                créé le: {formatTime(item.createdat)}
                {item.disabledat
                  ? " - désactivé le " + formatTime(item.createdat)
                  : ""}
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
}

const { Search } = Input;
const UpdatePassword = (props: any) => {
  const { editUser } = props;
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const [form] = Form.useForm();
  useEffect(() => {
    if (!form) return;
    form.resetFields();
  }, [form]);

  const handleValidate = async (values: any) => {
    try {
      if (!editUser || !editUser._id) return;
      await axios.patch(BASE_URL + "/admin-users", {
        password: values.password,
        _id: editUser._id,
      });
      message.success("Modification avec succès");
      form.resetFields();
      hide();
    } catch (error: any) {
      console.log(error);
      let msg = "";
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = "Une erreur est survenue. Veuillez réessayer";
      }
      message.error(msg);
    }
  };
  const handleGen = () => {
    try {
      if (!form) return;
      form.setFieldsValue({
        password: generateCode(8),
      });
    } catch (error) {
      console.error(error);
    }
  };
  const content = (
    <Form form={form} onFinish={handleValidate} layout="vertical">
      <Form.Item
        name="password"
        label="Mot de passe"
        rules={[
          {
            required: true,
            message: "Veuillez renseigner le mot de passe",
          },
          { max: 20, message: "Maximum 20 caractères autorisés" },
        ]}
      >
        <Search
          placeholder="mot de passe"
          allowClear
          enterButton="Générer mot de passe"
          onSearch={handleGen}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Valider
        </Button>
      </Form.Item>
    </Form>
  );

  if (!editUser || !editUser._id) return <></>;

  return (
    <Popover
      content={content}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Changer le mot de passe de {editUser.username}</div>
          <Button
            size="small"
            type="dashed"
            shape="circle"
            danger
            icon={<CloseOutlined />}
            onClick={hide}
          />
        </div>
      }
      visible={open}
    >
      <Button
        size="small"
        icon={<KeyOutlined />}
        style={{ marginLeft: "15px" }}
        onClick={() => setOpen(true)}
      >
        Changer le mot de passe
      </Button>
    </Popover>
  );
};
