import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../utils/Constants";
import axios from "axios";
import { RootState } from "../../store/reducers/rootReducer";
import { useSelector } from "react-redux";
import { HeaderContent } from "../../components/HeaderContent";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  Space,
  Table,
  Tag,
  Button,
  message,
  Popover,
  Select,
  Divider,
  Tabs,
  Tooltip,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { formatDate, formatTime, diff } from "../../utils/timeUtil";
import { Column } from "@ant-design/plots";
import { getFilters } from "../../utils/tableUtil";

const TOTAL_STEP = 14;

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log();
};

export default function ViewTrackDetailPage() {
  const { appcode } = useParams();
  const [tracks, settracks] = useState<any[]>([]);
  const [code, setcode] = useState<any>(null);
  useEffect(() => {
    if (!appcode) return;
    initAppCode();
  }, [appcode]);

  const initAppCode = async () => {
    try {
      const res: any = await axios.get(BASE_URL + "/appcodeById/" + appcode);
      if (res && res.data) {
        setcode(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (!appcode) return;
    initTracks();
  }, [appcode]);

  const initTracks = async () => {
    try {
      if (!appcode) return;
      const res: any = await axios.get(
        BASE_URL + "/tracking-by-code/" + appcode
      );

      if (res && Array.isArray(res.data)) {
        let arr = res.data.map((it: any) => {
          return { ...it, key: it._id };
        });

        settracks(arr);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* columns */
  const columns: ColumnsType<any> = [
    {
      title: "Session",
      dataIndex: "label",
      filters: [...getFilters("label", tracks)],
      filterSearch: true,
      onFilter: (value: any, record: any) => {
        return record.label.startsWith(value);
      },
      width: "10%",
      render: (_, record) => {
        try {
          return <span>{record.label}</span>;
        } catch (error) {
          console.error(error);
          return <></>;
        }
      },
    },
    {
      title: "Étape",
      dataIndex: "steplabel",
      width: "10%",
      filters: [...getFilters("steplabel", tracks)],
      filterSearch: true,
      onFilter: (value: any, record: any) => {
        return record.steplabel.startsWith(value);
      },
      render: (_, record) => {
        try {
          return <span>{record.steplabel}</span>;
        } catch (error) {
          return <></>;
        }
      },
    },
    {
      title: "Début",
      dataIndex: "startat",
      width: "10%",
      render: (_, record) => {
        try {
          return <span> {formatTime(record.startat)}</span>;
        } catch (error) {
          return <></>;
        }
      },
    },

    {
      title: "Fin",
      dataIndex: "endat",
      width: "10%",
      render: (_, record) => {
        try {
          return <span> {formatTime(record.endat)}</span>;
        } catch (error) {
          return <></>;
        }
      },
    },
    {
      title: "Durée",
      dataIndex: "endat",
      width: "10%",
      render: (_, record) => {
        try {
          if (!record.endat) return "";
          if (!record.startat) return "";

          return <span> {diff(record.startat, record.endat)}</span>;
        } catch (error) {
          return <></>;
        }
      },
    },
    {
      title: "Statut",
      dataIndex: "statussession",
      width: "10%",
      render: (_, record) => {
        try {
          const color =
            record.statussession === "fini"
              ? "green"
              : record.statussession === "en cours"
              ? "blue"
              : "red";
          return <Tag color={color}>{record.statussession}</Tag>;
        } catch (error) {
          return <></>;
        }
      },
    },
  ];
  if (!tracks) return <></>;
  if (!code) return <></>;

  return (
    <>
      <div>
        <HeaderContent title={"Détails du tracking du code: " + code.code} />
        {tracks && tracks.length > 0 && (
          <div className="content">
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Données" key="1">
                <Table
                  columns={columns}
                  dataSource={tracks}
                  onChange={onChange}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Graphique" key="2">
                <GraphicTab tracks={tracks} />
              </Tabs.TabPane>
            </Tabs>
          </div>
        )}
      </div>
    </>
  );
}
const GraphicTab = (props: any) => {
  const { tracks } = props;
  const [choicedTracks, setchoicedTracks] = useState(tracks);

  const countSession = (arr: any[]): string => {
    try {
      if (!arr || arr.length === 0) return "0";

      arr = arr.map((it: any) => {
        return it.label;
      });
      return Array.from(new Set<string>(arr)).length + "";
    } catch (error) {
      return "";
    }
  };
  const content = () => {
    try {
      if (!tracks) return <></>;
      const endedSessions = tracks.filter((it: any) => {
        return it.statussession === "fini";
      });
      const onRunningSession = tracks.filter((it: any) => {
        return it.statussession === "en cours";
      });
      const quitSessions = tracks.filter((it: any) => {
        return it.statussession === "abandon";
      });
      return (
        <>
          <div style={{ marginBottom: "40px" }}>
            <Tooltip title="Toutes">
              <Button
                type="primary"
                ghost
                icon={<ContainerOutlined />}
                style={{ marginRight: "15px" }}
                onClick={() => setchoicedTracks(tracks)}
              >
                Toutes {countSession(tracks)}
              </Button>
            </Tooltip>

            <Tooltip title="Fini(s)">
              <Button
                type="primary"
                icon={<CheckOutlined />}
                style={{ marginRight: "15px" }}
                onClick={() => setchoicedTracks(endedSessions)}
              >
                {countSession(endedSessions)} fini(s)
              </Button>
            </Tooltip>
            <Tooltip title="Abandon(s)">
              <Button
                danger
                icon={<CloseOutlined />}
                style={{ marginRight: "15px" }}
                onClick={() => setchoicedTracks(quitSessions)}
              >
                {countSession(quitSessions)} abandon(s){" "}
              </Button>
            </Tooltip>
            <Tooltip title="En cours">
              <Button
                icon={<LoadingOutlined />}
                onClick={() => setchoicedTracks(onRunningSession)}
              >
                En cours
              </Button>
            </Tooltip>
          </div>
          {choicedTracks && <StackGroupBySession tracks={choicedTracks} />}
        </>
      );
    } catch (error) {
      return <></>;
    }
  };
  return <>{content()}</>;
};
const StackGroupBySession = (props: any) => {
  const { tracks } = props;
  const [stackData, setstackData] = useState<any[]>([]);
  useEffect(() => {
    if (!tracks && tracks.length === 0) return;
    const arr: any[] = [];
    tracks.forEach((it: any) => {
      if (it.startat && it.endat) {
        const duration =
          (new Date(it.endat).getTime() - new Date(it.startat).getTime()) /
          1000;
        arr.push({
          session: it.label,
          duration: duration,
          step: it.steplabel,
        });
      }
    });
    setstackData(arr);
  }, [tracks]);
  const config = {
    data: stackData,
    xField: "session",
    yField: "duration",
    isGroup: true,
    isStack: true,
    seriesField: "step",
    groupField: "session",
  };
  return (
    <>
      {stackData.length > 0 ? (
        <>
          <Column {...config} />
        </>
      ) : (
        <>Aucun graphique disponible</>
      )}
    </>
  );
};
