import { catNameFrance } from './catNameFrance'

export const getCatFranceName = (id: string) => {
  let franceName = ''

  catNameFrance.forEach((item) => {
    if (item._id === id) {
      franceName = item.categoryName
    }
  })

  return franceName
}
