import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, message, Select } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { HeaderContent } from "../../components/HeaderContent";
import AppCodeGrid from "./AppCodeGrid";
import { BASE_URL } from "../../utils/Constants";
import axios from "axios";
import { Export } from "../../Models/GameSource/Export";
import { generateCode } from "../../utils/numberToString";
import { RootState } from "../../store/reducers/rootReducer";
import { useSelector } from "react-redux";
import moment from "moment";
import { AppCode } from "../../Models/GameSource/AppCode";
export default function AppCodePage() {
  const [exports, setexports] = useState<Export[]>([]);
  const [codes, setcodes] = useState<AppCode[]>([]);

  useEffect(() => {
    initExports();
    initCodes();
  }, []);
  const initExports = async () => {
    try {
      const res: any = await axios.get(BASE_URL + "/export");
      if (res && res.data) {
        setexports(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const initCodes = async () => {
    try {
      const res: any = await axios.get(BASE_URL + "/appcode");
      if (res && res.data) {
        const arr = res.data.map((it: any) => {
          return { ...it, key: it._id };
        });
        setcodes(arr);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <HeaderContent
        title="Gestion des codes"
        button={<ModalAddCode initCodes={initCodes} exports={exports} />}
      />
      {exports && exports.length > 0 ? (
        <div className="content">
          <AppCodeGrid codes={codes} exports={exports} initCodes={initCodes} />
        </div>
      ) : (
        <>
          Veuillez exporter au moins un scénario afin d'entrer dans cette
          interface
        </>
      )}
    </div>
  );
}
const { Option } = Select;
const { Search } = Input;
const ModalAddCode = (props: any) => {
  const { exports, initCodes } = props;
  const { user } = useSelector((state: RootState) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleValidate = async (values: any) => {
    try {
      if (!user) return;

      const data = { ...values, createdby: user?._id, disabled: false };
      await axios.post(BASE_URL + "/appcode", data);
      await initCodes();
      message.success("Ajout avec succès!");
      form.resetFields();
      handleCancel();
    } catch (error: any) {
      let msg = "";
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = "Une erreur est survenue. Veuillez réessayer";
      }
      message.error(msg);
    }
  };
  const handleGen = () => {
    try {
      if (!form) return;
      form.setFieldsValue({
        code: generateCode(8),
      });
    } catch (error) {
      console.error(error);
    }
  };
  if (!exports || !user) return <></>;
  return (
    <>
      <Button
        type="primary"
        onClick={() => showModal()}
        icon={<ExportOutlined />}
      >
        Nouveau code
      </Button>
      <Modal
        forceRender
        title="Ajouter un code d'application"
        visible={isModalOpen}
        cancelText="Fermer"
        onCancel={() => handleCancel()}
        okButtonProps={{ style: { display: "none" } }}
      >
        <Form form={form} onFinish={handleValidate} layout="vertical">
          <Form.Item
            name="code"
            label="Code"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner le code.",
              },
              { max: 10, message: "Maximum 10 caractères autorisés" },
            ]}
          >
            <Search
              placeholder="code"
              allowClear
              enterButton="Générer un code"
              onSearch={handleGen}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ max: 100, message: "Maximum 100 caractères autorisés" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="enabledat"
            label="Date d'activation"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner la date d'activation.",
              },
              { max: 100, message: "Maximum 100 caractères autorisés" },
            ]}
            initialValue={moment(new Date()).format("DD/MM/YYYY")}
          >
            <Input type="date" />
          </Form.Item>
          <Form.Item
            name="assignedto"
            label="Scénario"
            rules={[
              {
                required: true,
                message: "Sélectionner le scénario",
              },
            ]}
          >
            <Select
              onChange={(val) =>
                form.setFieldsValue({
                  assignedto: val,
                })
              }
              style={{ minWidth: 200 }}
              placeholder="scénario exporté"
            >
              {exports.map((ex: Export) => (
                <Option key={ex._id} value={ex._id}>
                  {ex.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Valider
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
