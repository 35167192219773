import { Button, Card, Layout, Modal, Upload } from 'antd'
import { HeaderContent } from '../components/HeaderContent'
import { styles } from '../styles/styles'
import ImgCrop from 'antd-img-crop'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AvatarService } from '../services/Account/AvatarService'
import { Network } from '../services/network'
import { Screen } from '../Models'
import { url } from '../Models/urls'
import { acceptedImages } from '../utils/AccepedImage'
import { playerAvatarSize } from '../utils/Constants'

const { Content } = Layout

export const PlayerAvatar = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [screen, setScreen] = useState<Screen>()
  const [isChange, setIsChange] = useState(false)
  const [newImageResized, setNewImageResized] = useState<File[]>([])
  const [isUpdate, setIsUpdate] = useState(false)

  const { id } = useParams()

  useEffect(() => {
    id && fetchAvatar()
  }, [id])

  useEffect(() => {
    const images: UploadFile[] = []
    screen &&
      screen.images.forEach((img) => {
        images.push({
          uid: '',
          name: '',
          status: 'done',
          url: url + img,
        })
      })

    setFileList(images)
  }, [screen])

  const fetchAvatar = async () => {
    if (id) {
      const res = await AvatarService.getAvataService(id)
      if (Network.isSuccess(res.status)) {
        setScreen(res.data[0])
      }
    }
  }
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList)
    setIsChange(true)
    const newResized: File[] = []
    //resize files
    newFileList.forEach((item) => {
      if (item.originFileObj) {
        const files: any = item.originFileObj

        if (files.type) {
          if (acceptedImages.includes(files.type)) {
            const img = new Image()
            img.src = URL.createObjectURL(files)
            img.onload = function () {
              const canvas = document.createElement('canvas')
              canvas.setAttribute('style', 'object-fit: cover')
              canvas.width = playerAvatarSize
              canvas.height = playerAvatarSize
              let ctx = canvas.getContext('2d')
              ctx?.drawImage(img, 0, 0, playerAvatarSize, playerAvatarSize)
              canvas.toBlob(
                (blob) => {
                  if (blob) {
                    const newImage = new File(
                      [blob],
                      files.name.split('.')[0] + '.jpeg',
                    )

                    newResized.push(newImage)
                    setNewImageResized(newResized)
                  }
                },
                'image/jpeg',
                0.8,
              )
            }
          } else {
            Modal.error({
              title: 'Attention',
              content: "Seuls les format d'image sont acceptés",
              okText: 'OK',
              cancelText: '',
            })
          }
        }
      }
    })
  }

  const handleUpdatePlayerAvatar = async () => {
    try {
      //setIsUpdate(true)

      const data = new FormData()

      data.append('screenName', 'Player account')
      data.append('_id', '6295c6a77078673ba634b43b')
      data.append('categoryId', '628f1e1fd8abd3c9f8f3d55a')

      if (fileList.length > 0) {
        fileList.forEach((item) => {
          item.url && data.append('images', item.url.split(url)[1])
        })
      }

      if (newImageResized.length > 0) {
        newImageResized.forEach((item) => {
          data.append('files', item)
        })
      }

      if (id) {
        const res = await AvatarService.UpdateAvatar(id, data)
        if (Network.isSuccess(res.status)) {
          setIsChange(false)
          setIsUpdate(false)
          setNewImageResized([])
          fetchAvatar()
        }
      }
    } catch (e) {
      const error: any = e
      Modal.error({
        title: 'Error',
        content: error.response.data.message,
      })

      setIsUpdate(false)
    }
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as RcFile)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  return (
    <Content style={styles.bodyComponent}>
      <HeaderContent title="Gestion d'avatars" />
      <Card>
        <ImgCrop rotate>
          <Upload
            accept={acceptedImages.toString()}
            className="picture-container"
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
          >
            Télécharger
          </Upload>
        </ImgCrop>
        {isChange && (
          <Button
            type="primary"
            loading={isUpdate}
            onClick={handleUpdatePlayerAvatar}
          >
            Mettre à jour la liste actuelle
          </Button>
        )}
      </Card>
    </Content>
  )
}
