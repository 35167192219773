import { Card, Checkbox, Form, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/reducers/rootReducer";
import {
  checkAuthRequest,
  fetchAuthRequest,
  logoutRequest,
} from "../store/actions/authActions/authActions";

export default function Login() {
  const dispatch = useDispatch();
  const {  error } = useSelector(
    (state: RootState) => state.auth
  );
  const onLogin = async (values: any) => {
    const data = { username: values.username, password: values.password };
    dispatch(fetchAuthRequest(data));
  };
  return (
    //Login screen
    <div className="container-fluid bg-light" style={{ height: "100vh" }}>
      <div className="row">
        <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
          <Card
            className="card-login"
            style={{ marginTop: 50, padding: "30px 20px" }}
          >
            <div className="text-center">
              <h1
                style={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  color: "#34495e",
                }}
              >
                TechCare
              </h1>
            </div>

            <Form
              layout="vertical"
              style={{ margin: "30px 0" }}
              onFinish={onLogin}
            >
              <Form.Item
                name="username"
                label="Nom d'utilisateur"
                rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner le nom d'utilisateur",
                  },
                ]}
              >
                <Input
                  placeholder="nom d'utilisateur"
                  size="large"
                  prefix={
                    <i
                      className="fas fa-user text-muted"
                      style={{ marginRight: 10 }}
                    ></i>
                  }
                />
              </Form.Item>

              <Form.Item
                name="password"
                label="Mot de passe"
                rules={[
                  {
                    required: true,
                    message: "Veuillez renseigner votre mot de passe",
                  },
                ]}
              >
                <Input.Password
                  placeholder="mot de passe"
                  size="large"
                  prefix={
                    <i
                      className="fas fa-lock text-muted"
                      style={{ marginRight: 10 }}
                    ></i>
                  }
                />
              </Form.Item>

              <Form.Item
                name="remember"
                initialValue={false}
                valuePropName="checked"
              >
                <Checkbox>Se souvenir de moi</Checkbox>
              </Form.Item>
              <p className="text-danger font-italic">{error}</p>
              <div className="text-center">
                <Form.Item>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      width: "50%",
                      color: "white",
                      fontWeight: "bold",
                      height: 45,
                      fontSize: 20,
                      backgroundColor: "#16a085",
                      border: "none",
                    }}
                  >
                    Connecter
                  </button>
                </Form.Item>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
}
