import { AxiosResponse } from 'axios'
import { Network } from '../network'
import { Profile } from '../../Models'

export class ProfileSevice {
  static async getProfileService(
    url: string,
    id: string,
  ): Promise<AxiosResponse<Profile>> {
    return await Network.getWithToken<Profile>({
      url: url + id,
    })
  }
}

export class ProfilesSevice {
  static async getPorfilesService(): Promise<AxiosResponse<Profile[]>> {
    return await Network.getWithToken<Profile>({
      url: '/profile',
    })
  }
}

export class PostProfileSevice {
  static async postProfileService(
    data: FormData,
  ): Promise<AxiosResponse<Profile>> {
    return await Network.postWithToken<Profile>({
      url: '/profile',
      data: data,
    })
  }
}

export class PutProfileSevice {
  static async putProfileService(
    data: FormData,
  ): Promise<AxiosResponse<Profile>> {
    return await Network.putWithToken<Profile>({
      url: '/profile',
      data: data,
    })
  }
}

export class DeleteProfileSevice {
  static async deleteProfileService(
    id: string,
  ): Promise<AxiosResponse<Profile>> {
    return await Network.deleteWithToken<Profile>({
      url: '/profile/' + id,
    })
  }
}
