import { Avatar, Button, Checkbox, Form, Input, Modal, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'
import { Contact } from '../Models'
import { url } from '../Models/urls'
import {
  PostContactSevice,
  PutContactSevice,
} from '../services/Contacts/ContactSevice'
import { acceptedImages } from '../utils/AccepedImage'
import { MAX_File_Size, messageAvatarSize } from '../utils/Constants'

interface Props {
  isVisible: boolean
  onCancel: () => void
  contact?: Contact
  fetchContact: () => void
}

export const UpserContacModal = (props: Props) => {
  const { isVisible, onCancel, fetchContact } = props
  const [avatar, setAvatar] = useState('')
  const [avatarFile, setAvatarFile] = useState<any>()

  const [form] = useForm()

  const contact = props.contact

  useEffect(() => {
    if (contact && isVisible) {
      form.setFieldsValue({
        name: contact.name,
        phone: contact.phone,
        isNPC: contact.isNPC,
      })

      contact.image && setAvatar(url + contact.image)
    }
  }, [contact, isVisible])

  const postContact = async (values: any) => {
    const data = new FormData()

    data.append('name', values.name)
    data.append('phone', values.phone)

    if (avatarFile) {
      data.append('file', avatarFile)
    } else {
      if (avatar) {
        data.append('image', avatar.split(url)[1])
      }
    }

    if (contact) {
      data.append('_id', contact._id)
    }

    data.append('isNPC', values.isNPC)
    // // post to data

    try {
      if (contact) {
        await PutContactSevice.putContactService(data)
      } else {
        await PostContactSevice.postContactService(data)
      }
      console.log('Done')
      fetchContact()
      closeModal()
    } catch (error) {
      const e: any = error
      Modal.error({
        title: 'Error',
        content: e.response.data.message,
      })
    }
  }

  const closeModal = () => {
    setAvatar('')
    form.resetFields()
    setAvatarFile(undefined)
    onCancel()
  }

  const handleUploadAvatar = async (files: any) => {
    if (!acceptedImages.includes(files[0].type)) {
      Modal.error({
        title: 'Attention',

        content: "Seuls les formats d'image sont acceptés",
        okText: 'OK',
      })
    } else {
      if (files[0].size < MAX_File_Size) {
        const urlBlob = URL.createObjectURL(files[0])
        setAvatar(urlBlob)
        const img = new Image()
        img.src = URL.createObjectURL(files[0])
        img.onload = function () {
          const canvas = document.createElement('canvas')
          //get width and height for calc ratio
          let width = img.width
          let height = img.height
          const ratio = messageAvatarSize / width
          //canvas.setAttribute('style', 'object-fit: cover')
          canvas.width = width * ratio
          canvas.height = height * ratio
          let ctx = canvas.getContext('2d')
          ctx?.drawImage(img, 0, 0, width * ratio, height * ratio)

          canvas.toBlob(
            function (blob) {
              if (blob) {
                const newImage = new File(
                  [blob],
                  files[0].name.split('.')[0] + '.jpeg',
                )
                //console.log(newImage)
                setAvatarFile(newImage)
              }
            },
            'image/jpeg',
            0.8,
          )
        }
      } else {
        Modal.confirm({
          title: 'Attention',
          content: "Taille maximum de l'image est de 5MB",
        })
      }
    }
  }

  const handleRemoveImage = () => {
    setAvatar('')
    setAvatarFile(undefined)
  }

  return (
    <Modal
      forceRender
      footer={null}
      visible={isVisible}
      onCancel={() => closeModal()}
    >
      <Form form={form} layout="vertical" onFinish={postContact}>
        <div className="text-center" style={{ margin: '20px 0' }}>
          <label htmlFor="inpAvatar">
            <Avatar
              src={
                avatar
                  ? avatar
                  : 'https://img.icons8.com/ios-filled/150/e0e0e0/stack-of-photos--v1.png'
              }
              style={{ width: 150, height: 150 }}
            />

            <p className="text-muted" style={{ marginTop: 10 }}>
              Sélectionner un avatar <br /> (Max: 5.0 Mb)
            </p>
          </label>
          {avatar ? (
            <p>
              <button
                onClick={handleRemoveImage}
                className="btn btn-sm btn-light"
              >
                <i className="fas fa-trash-alt text-muted"></i> Supprimer
                l'image
              </button>
            </p>
          ) : null}
          <div className="d-none">
            <input
              accept={acceptedImages.toString()}
              type="file"
              id="inpAvatar"
              onChange={(e) => handleUploadAvatar(e.target.files)}
            />
          </div>
        </div>

        <Form.Item
          name="name"
          label="Nom"
          rules={[
            {
              required: true,
              message: 'Veuillez renseigner un nom',
            },
          ]}
        >
          <Input
            disabled={contact && contact.isNPC ? true : false}
            placeholder="Nom"
            type="text"
          />
        </Form.Item>

        <Form.Item name="phone" label="Téléphone">
          <Input placeholder="phone" type="tel" />
        </Form.Item>

        <Form.Item name="isNPC" valuePropName="checked" initialValue={false}>
          <Checkbox>Personnage du jeu ?</Checkbox>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button htmlType="submit" type="primary">
              {contact ? 'Modifier' : 'Valider'}
            </Button>
            <Button onClick={() => form.resetFields()}>Réinitialiser</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}
