import { Outlet } from "react-router-dom";
import { Modal, Button, Layout, Menu, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { HashRouter, Link, Route, Routes } from "react-router-dom";
import { Category } from "../Models";
import { styles } from "../styles/styles";
import { router } from "../routes/router";
import { ChangePassword } from "../modals/ChangePassword";
import { CategoryService, Network } from "../services";
import { getCatFranceName } from "../utils/get_cat_france_name";
import { logoutRequest } from "../store/actions/authActions/authActions";

const { Footer, Sider, Content } = Layout;
export default function AdminLayout() {
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isVisibleChangePassword, setIsVisibleChangePassword] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Confirmez-vous la déconnexion",
      okText: "Déconnecter",
      cancelText: "Annuler",
      onOk: () => {
        dispatch(logoutRequest());
      },
    });
  };
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    if (width <= 768) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [window.innerWidth]);

  useEffect(() => {
    getCategories();
  }, []);

  //remove space in title
  function removeAccents(str: string) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/đ/g, "d")
      .replace(/Đ/g, "D");
  }

  const getCategories = async () => {
    try {
      const res = await CategoryService.getCategories();
      if (Network.isSuccess(res.status)) {
        const data = res.data;

        const itemsCat: {}[] = [];
        data.forEach((item: Category) => {
          const titleCat = removeAccents(item.categoryName)
            .toLocaleLowerCase()
            .replace(/ /g, "-");
          let icon;
          let params = "";
          switch (titleCat) {
            case "contacts":
              icon = (
                <i style={styles.menuIcon} className="fas fa-address-book"></i>
              );
              break;
            case "-player-account":
              icon = <i style={styles.menuIcon} className="fas fa-user"></i>;
              params = "/" + item._id;
              break;
            case "note":
              icon = (
                <i style={styles.menuIcon} className="fas fa-clipboard"></i>
              );
              break;
            case "pop-up":
              icon = (
                <i style={styles.menuIcon} className="fas fa-bullhorn"></i>
              );
              params = "/" + item._id;
              break;
            case "group-detective-messages":
              icon = <i style={styles.menuIcon} className="fas fa-users"></i>;
              params = "/" + item._id;
              break;
            case "leo-messages":
              icon = <i style={styles.menuIcon} className="fas fa-user"></i>;
              params = "/" + item._id;
              break;
            case "map-location":
              icon = (
                <i
                  style={styles.menuIcon}
                  className="fas fa-map-marked-alt"
                ></i>
              );
              break;
            case "pixagram-profiles":
              icon = <i style={styles.menuIcon} className="fas fa-users"></i>;
              break;
            // case 'uploadfile':
            //   icon = <i style={styles.menuIcon} className="fas fa-upload"></i>
            //   break
            case "new-feed-pixagram":
              icon = (
                <i style={styles.menuIcon} className="fas fa-comment-dots"></i>
              );
              break;
            default:
              icon = <i className="fas fa-stream" style={styles.menuIcon}></i>;
              break;
          }

          if (titleCat !== "uploadfile" && titleCat !== "new-feed-pixagram") {
            itemsCat.push({
              key: item._id,
              label: (
                <Link
                  to={"/admin/" + titleCat + params}
                  style={{ ...styles.menuItem, textTransform: "capitalize" }}
                >
                  {getCatFranceName(item._id)}
                </Link>
              ),

              icon: icon,
            });
          }
        });
        /* add new menu item */
        itemsCat.push({
          key: Math.random() + "".replace(".", ""),
          label: (
            <Link to="/admin/export" style={{ textTransform: "capitalize" }}>
              Export
            </Link>
          ),

          icon: <i className="fas fa-file-export" style={styles.menuIcon}></i>,
        });
        itemsCat.push({
          key: Math.random() + "".replace(".", ""),
          label: (
            <Link to="/admin/appcode" style={{ textTransform: "capitalize" }}>
              Gestion des codes
            </Link>
          ),

          icon: <i className="fas fa-barcode" style={styles.menuIcon}></i>,
        });
        itemsCat.push({
          key: Math.random() + "".replace(".", ""),
          label: (
            <Link to="/admin/tracking" style={{ textTransform: "capitalize" }}>
              Tableau de suivie
            </Link>
          ),

          icon: <i className="fas fa-chart-line" style={styles.menuIcon}></i>,
        });
        itemsCat.push({
          key: Math.random() + "".replace(".", ""),
          label: (
            <Link to="/admin/users" style={{ textTransform: "capitalize" }}>
              Gestion des utilisateurs
            </Link>
          ),
          icon: <i className="fas fa-sitemap" style={styles.menuIcon}></i>,
        });

        setMenuItems(itemsCat);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Layout>
        <Sider
          width={250}
          collapsed={isCollapsed}
          style={{
            minHeight: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Row justify="center" style={{ padding: 10 }}>
            {isCollapsed || (
              <Link to="/">
                <h1
                  style={{
                    textTransform: "uppercase",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 24,
                  }}
                >
                  TechCare
                </h1>
              </Link>
            )}
          </Row>
          <Menu theme="dark" mode="inline" items={menuItems} />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              left: 0,
              padding: "20px 0",
            }}
          >
            <div className="text-center">
              <p className="text-capitalize text-light">
                Bonjour {localStorage.getItem("username")}
              </p>
              <>
                <button
                  className="btn btn-sm btn-light"
                  style={{
                    backgroundColor: "#000b14",
                    border: "none",
                    color: "#ecf0f1",
                    padding: "5px 20px",
                  }}
                  onClick={handleLogout}
                >
                  <i className="fas fa-power-off"></i>{" "}
                  {isCollapsed ? "" : "Déconnecter"}
                </button>
                <br />
                <Button
                  type="text"
                  className="text-muted"
                  onClick={() => setIsVisibleChangePassword(true)}
                >
                  {width < 768 ? (
                    <i className="fas fa-redo"></i>
                  ) : (
                    "Changer le mot de passe"
                  )}
                </Button>
              </>
            </div>
          </div>
        </Sider>
        <Layout style={{ marginLeft: isCollapsed ? 80 : 250 }}>
          <Content style={styles.bodyComponent}>
            <Outlet />
          </Content>
          <Footer style={{ backgroundColor: "#212121", color: "#fafafa" }}>
            <Row justify="center">
              <p>
                <i>&copy; 2022 | TechCare</i>
              </p>
              {/* <p style={{ padding: '0 20px' }}>
                Created by VNTT Solution - TDM City - Binh Duong Provice
              </p> */}
            </Row>
          </Footer>
        </Layout>
        <ChangePassword
          visible={isVisibleChangePassword}
          onCancel={() => setIsVisibleChangePassword(false)}
        />
      </Layout>
    </>
  );
}
