import axios from 'axios'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { IPost } from '../../../Models/IPost'
import {
  fetchPostsFailure,
  fetchPostsSuccess,
} from '../../actions/postActions/postActions'
import { postTypes } from '../../actionTypes/postTypes'
import { ResponseGenerator } from '../type'

const getPosts = () =>
  axios.get<IPost[]>('https://jsonplaceholder.typicode.com/todos')

function* fetchPostsSaga() {
  try {
    const response: ResponseGenerator = yield call(getPosts)
    yield put(
      fetchPostsSuccess({
        posts: response.data,
      }),
    )
  } catch (e) {
    // Add this because Prettier auto remove 'any' if format
    const error: any = e
    yield put(
      fetchPostsFailure({
        error: error.message,
      }),
    )
  }
}

function* postsSaga() {
  yield all([takeLatest(postTypes.FETCH_POST_REQUEST, fetchPostsSaga)])
}

export default postsSaga
