export const getFilters = (field: string, items: any[]): any[] => {
  try {
    if (!items || items.length === 0) return [];
    const mySet = new Set<string>();
    items.forEach((it: any) => mySet.add(it[field]));
    return Array.from(mySet).map((it: string) => {
      return { text: it, value: it };
    });
  } catch (error) {
    return [];
  }
};
