import {
  DeleteOutlined,
  EditFilled,
  ExclamationCircleOutlined,
  SnippetsOutlined,
} from '@ant-design/icons'
import { Button, Card, List, message, Modal, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useEffect, useState } from 'react'
import { Note } from '../Models'
import { styles } from '../styles/styles'
import { HeaderContent } from '../components/HeaderContent'
import { NotesUpserModal } from '../modals'
import { DeleteNoteSevice, NoteSevice } from '../services/Note/NoteSevice'
import { Network } from '../services'

export const Notes = () => {
  const [notes, setNotes] = useState<Note[]>([])
  const [note, setNote] = useState<Note>()
  const [isVisibleModalNote, setIsVisibleModalNote] = useState(false)
  const [showNote, setShowNote] = useState(false)

  const { Title, Text } = Typography

  useEffect(() => {
    fetchNotes()
  }, [])

  const showModalAddNote = (note?: Note) => {
    setNote(note)
    setIsVisibleModalNote(true)
  }

  const onCloseModalAddNote = () => {
    setIsVisibleModalNote(false)
  }

  const fetchNotes = async () => {
    try {
      const res = await NoteSevice.getNoteService()
      if (Network.isSuccess(res.status)) {
        setNotes(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const delNote = (id: string) => {
    Modal.confirm({
      title: 'Attention',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirmez-vous la suppression de la note',
      okText: 'Supprimer',
      cancelText: 'Annuler',
      onOk: async () => {
        try {
          await DeleteNoteSevice.deleteNoteService(id).then(() => {
            message.success('Suppression avec succès!')
            fetchNotes()
          })
        } catch (error) {
          console.log(error)
        }
      },
    })
  }

  const showModalNote = (note: Note) => {
    setNote(note)
    setShowNote(true)
  }

  return (
    <Content style={styles.bodyComponent}>
      <HeaderContent
        title="Notes"
        button={
          <Button
            type="primary"
            icon={<SnippetsOutlined style={{ fontSize: 20 }} />}
            onClick={() => showModalAddNote()}
            key="notes"
          >
            Nouveau
          </Button>
        }
      />
      <div className="content">
        <Card>
          <List>
            {notes.map((note) => (
              <List.Item
                key={note._id}
                actions={[
                  <Button
                    type="text"
                    icon={<EditFilled />}
                    onClick={() => showModalAddNote(note)}
                  ></Button>,
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    type="primary"
                    onClick={() => delNote(note._id)}
                  ></Button>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <a
                      style={{ fontSize: 20 }}
                      onClick={() => showModalNote(note)}
                    >
                      {note.title}
                    </a>
                  }
                  description={note.content.slice(0, 10) + '...'}
                />
              </List.Item>
            ))}
          </List>
        </Card>
      </div>

      <NotesUpserModal
        note={note}
        isVisible={isVisibleModalNote}
        onCancel={onCloseModalAddNote}
        fetchNotes={fetchNotes}
      />
      <Modal
        getContainer={false}
        title="Note"
        visible={showNote}
        onOk={() => setShowNote(false)}
        onCancel={() => setShowNote(false)}
        footer={null}
      >
        <Title level={3}>{note?.title}</Title>
        <Text type="secondary">{note?.additionDetail}</Text>
        <p style={{ marginTop: 20, whiteSpace: 'pre-wrap' }}>{note?.content}</p>
      </Modal>
    </Content>
  )
}
