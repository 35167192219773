import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DeleteOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Row, message } from "antd";
import { DefaultImage } from "../../utils/defaultImage";
import { Export } from "../../Models/GameSource/Export";
import { REF_STATUS } from "../../utils/Constants";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";
import { RootState } from "../../store/reducers/rootReducer";
import { useSelector } from "react-redux";
const { Meta } = Card;

export default function ExportGrid(props: any) {
  const { exports, initExports } = props;
  const { user } = useSelector((state: RootState) => state.auth);
  const deleteExport = async (item: Export) => {
    try {
      await axios.delete(BASE_URL + "/export/" + item._id);
      await initExports();
      message.success("Suppression avec succès!");
    } catch (error: any) {
      console.error(error);
      let msg = "";
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = "Une erreur est survenue. Veuillez réessayer";
      }
      message.error(msg);
    }
  };
  const getStatus = (item: Export): string => {
    try {
      const status = REF_STATUS.find((it) => {
        return String(it.code) === String(item.status);
      });
      return status ? status.label : "";
    } catch (error) {
      return "";
    }
  };
  return (
    <>
      {exports && exports.length > 0 && (
        <Row style={{ marginTop: 20 }}>
          {exports.map((item: Export) => (
            <Card
              key={item._id}
              style={{ width: 300, margin: 5 }}
              cover={
                <img
                  style={{
                    width: "100%",
                    height: 200,
                    objectFit: "cover",
                  }}
                  alt={item.name}
                  src={DefaultImage}
                />
              }
              actions={
                user && user.profile === "admin"
                  ? [
                      <Link to={`/admin/export/${item._id}`}>
                        {<EditOutlined />}
                      </Link>,
                      <Button
                        type="text"
                        onClick={() => deleteExport(item)}
                        key="ellipsis"
                        icon={<DeleteOutlined />}
                        danger
                        disabled={item.status === 4}
                      />,
                    ]
                  : [
                      <Link to={`/research/export/${item._id}`}>
                        <Button
                          type="text"
                          key="ellipsis"
                          icon={<EyeOutlined />}
                          title="Voir le contenu du scénario exporté"
                        />
                        ,
                      </Link>,
                    ]
              }
            >
              <Meta
                style={{ maxHeight: 200 }}
                title={item.name}
                description={getStatus(item)}
                className="card-description"
              />
            </Card>
          ))}
        </Row>
      )}
    </>
  );
}
