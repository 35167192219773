import { Button, Form, Input, Modal, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'
import { Note } from '../Models'
import { PostNoteSevice, PutNoteSevice } from '../services/Note/NoteSevice'

interface Props {
  note?: Note
  isVisible: boolean
  onCancel: () => void
  fetchNotes: () => void
}

export const NotesUpserModal = (props: Props) => {
  const { isVisible, onCancel, fetchNotes } = props
  const [loadding, setLoadding] = useState(false)
  const [form] = useForm()

  const note = props.note

  useEffect(() => {
    if (note && isVisible) {
      form.setFieldsValue({
        title: note.title,
        content: note.content,
        additionDetail: note.additionDetail,
      })
    }
  }, [note, isVisible])

  const postNote = async (values: any) => {
    setLoadding(true)

    const data = {
      title: values.title,
      content: values.content,
      additionDetail: values.additionDetail,
      _id: note ? note._id : null,
      isLocation: 'false',
    }

    try {
      if (note) {
        await PutNoteSevice.putNoteService(data)
      } else {
        await PostNoteSevice.postNoteService(data)
      }

      fetchNotes()
      closeModal()
      setLoadding(false)
      form.resetFields()
    } catch (e) {
      console.log(e)
    }
  }

  const closeModal = () => {
    form.resetFields()
    onCancel()
  }

  return (
    <Modal
      forceRender
      width={768}
      visible={isVisible}
      footer={null}
      onCancel={closeModal}
    >
      <h2>{note ? 'Modification' : 'Nouveau'}</h2>

      <div style={{ padding: '20px 0' }}>
        <Form
          form={form}
          layout="vertical"
          name="note-form"
          onFinish={postNote}
        >
          <Form.Item
            label="Titre"
            name="title"
            rules={[
              {
                required: true,
                message: 'Veuillez renseigner un titre!',
              },
            ]}
          >
            <Input placeholder="..." />
          </Form.Item>
          <Form.Item name="additionDetail" label="informations complémentaires">
            <Input placeholder="date,l'heure ..." />
          </Form.Item>

          <Form.Item
            name="content"
            label="Contenu"
            rules={[
              {
                required: true,
                message: 'Veuillez renseigner une note valide!',
              },
            ]}
          >
            <Input.TextArea placeholder="contenu..." rows={8} />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button loading={loadding} type="primary" htmlType="submit">
                {note ? 'Modifier' : 'Valider'}
              </Button>
              <Button onClick={() => form.resetFields()}>Réinitialiser</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
