export const acceptedImages = ["image/jpg", "image/png", "image/jpeg"];
export const isImageUrl = (str: string): boolean => {
  try {
    return (
      str.endsWith(".jpg") ||
      str.endsWith(".png") ||
      str.endsWith(".jpeg") ||
      str.endsWith(".gif")
    );
  } catch (error) {
    return false;
  }
};
