import {  useSelector } from "react-redux";
import { RootState } from "../store/reducers/rootReducer";
import { Navigate, useLocation } from "react-router-dom";
import { Account } from "../Models";

interface Props {
  children?: any;
}

export default function RequireRech(props: Props) {
  const { isAuthenticated, user, error } = useSelector(
    (state: RootState) => state.auth
  );
  const location = useLocation();
  return isAuthenticated === true && isAdminProfile(user) ? (
    props.children
  ) : (
    <Navigate to="/401" replace state={{ path: location.pathname }} />
  );
}
function isAdminProfile(user: any): boolean {
  try {
    return user?.profile === "research";
  } catch (error) {
    return false;
  }
}
