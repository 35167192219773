import { AxiosResponse } from 'axios'
import { Network } from '../network'
import { Screen } from '../../Models'

export class ScreenSevice {
  static async getScreenService(
    url: string,
    id: string,
  ): Promise<AxiosResponse<Screen[]>> {
    return await Network.getWithToken<Screen[]>({
      url: url,
      params: { categoryId: id },
    })
  }
}

export class PutScreenSevice {
  static async putScreenSevice(data: any): Promise<AxiosResponse<Screen>> {
    return await Network.putWithToken<Screen>({
      url: '/screens',
      data: data,
    })
  }
}

export class PostScreenSevice {
  static async postScreenSevice(data: any): Promise<AxiosResponse<Screen>> {
    return await Network.postWithToken<Screen>({
      url: '/screens',
      data: data,
    })
  }
}

export class DeleteScreenSevice {
  static async deleteScreenSevice(id: string): Promise<AxiosResponse> {
    return await Network.deleteWithToken({
      url: '/screens/' + id,
    })
  }
}
