export const numberToString = (number: number) => {
  let str = "";
  if (number) {
    if (number > 1000) {
      str = (number / 1000).toFixed(1) + "K";
    } else {
      str = number + "";
    }
  } else {
    str = "0";
  }

  return str;
};
export const generateCode = (length: number): string => {
  try {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  } catch (error) {
    return "";
  }
};
