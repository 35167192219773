import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, message, Select } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { HeaderContent } from "../../components/HeaderContent";
import { BASE_URL } from "../../utils/Constants";
import axios from "axios";
import { Export } from "../../Models/GameSource/Export";
import { generateCode } from "../../utils/numberToString";
import { RootState } from "../../store/reducers/rootReducer";
import { useSelector } from "react-redux";
import moment from "moment";
import { AppCode } from "../../Models/GameSource/AppCode";

import ExportGrid from "./ExportGrid";

export const ExportPage = () => {
  const [exports, setexports] = useState<Export[]>([]);
  useEffect(() => {
    initExports();
  }, []);
  const initExports = async () => {
    try {
      const res: any = await axios.get(BASE_URL + "/export");
      if (res && res.data) {
        const arr = res.data.map((it: any) => {
          return { ...it, key: it._id };
        });
        setexports(arr);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <HeaderContent
        title="Gestion des exports"
        button={
          <ModalCreateExport initExports={initExports} exports={exports} />
        }
      />
      <div className="content">
        <ExportGrid exports={exports} initExports={initExports} />
      </div>
    </div>
  );
};
const { Option } = Select;
const { Search } = Input;
const ModalCreateExport = (props: any) => {
  const { initExports } = props;
  const { user } = useSelector((state: RootState) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (!form) return;
    form.resetFields();
  }, [form]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleValidate = async (values: any) => {
    try {
      if (!user) return;

      const data = { ...values, createdby: user?._id, disabled: false };
      console.log(data);
      await axios.post(BASE_URL + "/export", data);
      await initExports();
      message.success("Ajout avec succès!");
      form.resetFields();
      handleCancel();
    } catch (error: any) {
      let msg = "";
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = "Une erreur est survenue. Veuillez réessayer";
      }
      message.error(msg);
    }
  };
  const handleGen = () => {
    try {
      if (!form) return;
      form.setFieldsValue({
        code: generateCode(8),
      });
    } catch (error) {
      console.error(error);
    }
  };
  if (!user) return <></>;
  if (user.profile !== "admin") return <></>;
  return (
    <>
      <Button
        type="primary"
        onClick={() => showModal()}
        icon={<ExportOutlined />}
      >
        Nouvel export
      </Button>
      <Modal
        forceRender
        title="Ajouter un code d'application"
        visible={isModalOpen}
        cancelText="Fermer"
        onCancel={() => handleCancel()}
        okButtonProps={{ style: { display: "none" } }}
      >
        <Form form={form} onFinish={handleValidate} layout="vertical">
          <Form.Item
            name="code"
            label="Code"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner le code.",
              },
              { max: 10, message: "Maximum 10 caractères autorisés" },
            ]}
          >
            <Search
              placeholder="code"
              allowClear
              enterButton="Générer un code"
              onSearch={handleGen}
            />
          </Form.Item>
          <Form.Item
            name="name"
            label="Nom du scénario exporté"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner le nom.",
              },
              { max: 100, message: "Maximum 100 caractères autorisés" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Valider
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
