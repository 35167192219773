import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Tag, Button, Popover } from "antd";
import { CloseOutlined, AreaChartOutlined } from "@ant-design/icons";

import type { ColumnsType, TableProps } from "antd/es/table";

import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers/rootReducer";
import { getFilters } from "../../utils/tableUtil";
import { RadialBar } from "@ant-design/plots";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log();
};

export default function TrackingGrid(props: any) {
  const { tracks, dataTrack, initTracks } = props;
  const { user } = useSelector((state: RootState) => state.auth);
  /* columns */
  const columns: ColumnsType<any> = [
    {
      title: "Code",
      dataIndex: "code",
      filters: [...getFilters("session", tracks)],
      filterSearch: true,
      sorter: (a, b) => {
        try {
          return a.appcode.code.localCompare(b.appcode.code);
        } catch (error) {
          return false;
        }
      },
      onFilter: (value: any, record: any) => {
        return record.code.startsWith(value);
      },
      width: "10%",
      render: (_, record) => {
        try {
          return <span>{record.appcode.code}</span>;
        } catch (error) {
          console.error(error);
          return <></>;
        }
      },
    },
    {
      title: "Session",
      dataIndex: "sessioncount",

      width: "10%",
      render: (_, record) => {
        try {
          if (!record.sessions) return "";
          return <span>{record.sessions.length} session(s)</span>;
        } catch (error) {
          console.error(error);
          return <></>;
        }
      },
    },
    {
      title: "Statut",
      dataIndex: "disabled",
      width: "10%",
      render: (_, record) => {
        try {
          const color = record.disabled ? "red" : "green";
          const label = record.disabled ? "désactivé" : "activé";
          return <Tag color={color}>{label}</Tag>;
        } catch (error) {
          return <></>;
        }
      },
    },
    {
      title: "Graphique",
      dataIndex: "graphic",
      key: "xy",
      width: "10%",
      render: (_, record) => {
        try {
          return <PopChart dataTrack={dataTrack} item={record} />;
        } catch (error) {
          return <></>;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (_, record) => {
        try {
          const profile = user?.profile;
          return (
            <Link to={"/" + profile + "/tracking/" + record.appcode._id}>
              <Button type="primary">Détail</Button>
            </Link>
          );
        } catch (error) {
          return <></>;
        }
      },
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={tracks} onChange={onChange} />
    </>
  );
}
const TOTAL_STEP = 14;
const PopChart = (props: any) => {
  const { dataTrack, item } = props;
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const getData = (): any[] => {
    try {
      const myMap = new Map<string, number>();
      dataTrack.forEach((it: any) => {
        const code: string = it.appcode.code;

        if (code === item.appcode.code) {
          const curSession: string = it.label;

          if (myMap.has(curSession)) {
            const val: any = myMap.get(curSession);
            myMap.set(curSession, val + 1);
          } else {
            myMap.set(curSession, 1);
          }
        }
      });
      const arr: any[] = [];
      let i = 1;
      for (let entry of Array.from(myMap.entries())) {
        let key = entry[0];
        let value = entry[1];
        arr.push({
          session: key,
          steps: value,
        });
        i++;
      }
      /*     arr.push({
        session: " ",
        steps: TOTAL_STEP + 1,
      }); */
      return arr;
    } catch (error) {
      return [];
    }
  };
  const config = {
    data: [...getData()],
    xField: "session",
    yField: "steps",
    maxAngle: 350,
    radius: 0.8,
    innerRadius: 0.2,
    tooltip: {
      formatter: (datum: any) => {
        return {
          name: "Etapes réalisées",
          value: datum.steps,
        };
      },
    },
    colorField: "steps",
    color: (item: any) => {
      if (item.steps > 1) {
        return "#6349ec";
      } else if (item.steps > 4) {
        return "#ff9300";
      }
      return "#ff93a7";
    },
  };
  const content = <RadialBar {...config} />;
  return (
    <Popover
      style={{ width: "600px!important" }}
      content={content}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Graphique du code: {item.appcode.code}</div>
          <Button
            style={{ marginLeft: "15px" }}
            size="small"
            type="dashed"
            shape="circle"
            danger
            icon={<CloseOutlined />}
            onClick={hide}
          />
        </div>
      }
      visible={open}
    >
      <Button
        type="dashed"
        danger
        shape="circle"
        size="small"
        icon={<AreaChartOutlined />}
        onClick={() => setOpen(true)}
      ></Button>
    </Popover>
  );
};
