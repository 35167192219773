import React, { useEffect, useState } from "react";
import {
  Space,
  Table,
  Tag,
  Button,
  message,
  Popover,
  Select,
  Divider,
} from "antd";
import { EditOutlined } from "@ant-design/icons";

import type { ColumnsType, TableProps } from "antd/es/table";
import { BASE_URL } from "../../utils/Constants";
import axios from "axios";
import { AppCode } from "../../Models/GameSource/AppCode";
import { Export } from "../../Models/GameSource/Export";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};
export default function AppCodeGrid(props: any) {
  const { codes, exports, initCodes } = props;
  const [open, setOpen] = useState<any>({ code: null, visible: false });

  const handleUpdAppCode = async (type: string, code: any): Promise<void> => {
    try {
      if (type === "disable") {
        delete code.assignedto;
        code = {
          ...code,
          disabled: true,
          disabledat: new Date(),
          enabledat: null,
        };
      } else if (type === "enable") {
        delete code.assignedto;
        code = {
          ...code,
          disabled: false,
          disabledat: null,
          enabledat: new Date(),
        };
      } else if (type === "assign") {
        console.log("assign", code);
      }
      await axios.patch(BASE_URL + "/appcode", code);
      await initCodes();
      setOpen({ code: null, visible: false });
      message.success("Mise à jour avec succès!");
    } catch (error: any) {
      let msg = "";
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = "Une erreur est survenue. Veuillez réessayer";
      }
      message.error(msg);
    }
  };
  /* columns */
  const columns: ColumnsType<any> = [
    {
      title: "Code",
      dataIndex: "code",
      onFilter: (value: any, record: any) => {
        return record.name.startsWith(value);
      },
      width: "10%",
    },
    {
      title: "Statut",
      dataIndex: "disabled",
      width: "10%",
      render: (_, record) => {
        try {
          const color = record.disabled ? "red" : "green";
          const label = record.disabled ? "désactivé" : "activé";
          return <Tag color={color}>{label}</Tag>;
        } catch (error) {
          return <></>;
        }
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "30%",
    },
    {
      title: "Date de création",
      dataIndex: "createat",
      width: "20%",
      render: (_, record) => {
        try {
          const dtSplit = record.createat.split("T");
          return <span>{dtSplit[0]} </span>;
        } catch (error) {
          return <></>;
        }
      },
    },
    {
      title: "Scénario affecté",
      dataIndex: "assignedto",
      width: "20%",
      render: (_, record) => {
        try {
          if (record.assignedto) {
            return (
              <div>
                {record.assignedto.name}
                {record.disabled || record.assignedto.status === 4 ? (
                  <></>
                ) : (
                  <Popover
                    visible={open.code === record._id && open.visible}
                    trigger="click"
                    content={
                      <SelectExport
                        code={record}
                        exports={exports}
                        handleUpdAppCode={handleUpdAppCode}
                        handleClose={() =>
                          setOpen({ code: null, visible: false })
                        }
                      />
                    }
                    title="Sélection du scénario"
                  >
                    <Button
                      onClick={() =>
                        setOpen({ code: record._id, visible: true })
                      }
                      size="small"
                      style={{ marginLeft: "5px" }}
                      shape="circle"
                      icon={<EditOutlined />}
                      disabled={record.disabled}
                    />
                  </Popover>
                )}
              </div>
            );
          }
          return <></>;
        } catch (error) {
          return <></>;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => {
        try {
          if (record.disabled) {
            return (
              <Button
                type="primary"
                onClick={() => handleUpdAppCode("enable", record)}
              >
                Activer
              </Button>
            );
          }
          return (
            <Button
              type="primary"
              danger
              onClick={() => handleUpdAppCode("disable", record)}
            >
              Désactiver
            </Button>
          );
        } catch (error) {
          return <></>;
        }
      },
    },
  ];
  return (
    <>
      <Table columns={columns} dataSource={codes} onChange={onChange} />
    </>
  );
}
const { Option } = Select;
const SelectExport = (props: any) => {
  const { code, exports, handleUpdAppCode, handleClose } = props;
  const [currentExport, setcurrentExport] = useState(null);
  useEffect(() => {
    if (!code) return;
    if (code.assignedto) {
      setcurrentExport(code.assignedto._id);
    }
  }, [code]);
  const isChanged =
    code && code.assignedto && code.assignedto._id !== currentExport;
  const handleSelect = async () => {
    try {
      if (!isChanged) return;
      if (!currentExport) return;
      const newCode = { ...code, assignedto: currentExport };
      await handleUpdAppCode("assign", newCode);
    } catch (error) {
      console.error(error);
    }
  };
  if (!exports || exports.length == 0)
    return <span>Aucun scénario disponible</span>;
  return (
    <>
      <Select
        value={currentExport}
        style={{ width: 120 }}
        onChange={(value) => setcurrentExport(value)}
      >
        {exports.map((e: Export, i: number) => (
          <Option key={i} value={e._id}>
            {e.name}
          </Option>
        ))}
      </Select>
      <Button
        style={{ marginLeft: "5px" }}
        size="small"
        type="primary"
        onClick={() => handleSelect()}
      >
        Valider
      </Button>
      <Divider />
      <Button
        style={{ marginLeft: "5px" }}
        size="small"
        type="link"
        onClick={() => handleClose()}
      >
        Fermer
      </Button>
    </>
  );
};
