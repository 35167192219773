import { AxiosResponse } from 'axios'
import { Note } from '../../Models/GameSource/Note'
import { Network } from '../network'

export class NoteSevice {
  static async getNoteService(): Promise<AxiosResponse<Note[]>> {
    return await Network.getWithToken<Note[]>({
      url: '/note',
    })
  }
}

export class PostNoteSevice {
  static async postNoteService(data: any): Promise<AxiosResponse<Note>> {
    return await Network.postWithToken<Note>({
      url: '/note',
      data: data,
    })
  }
}

export class PutNoteSevice {
  static async putNoteService(data: any): Promise<AxiosResponse<Note>> {
    return await Network.putWithToken<Note>({
      url: '/note',
      data: data,
    })
  }
}

export class DeleteNoteSevice {
  static async deleteNoteService(id: string): Promise<AxiosResponse<Note>> {
    return await Network.deleteWithToken<Note>({
      url: '/note/' + id,
    })
  }
}
