import { AxiosResponse } from 'axios'
import { Screen } from '../../Models'
import { Network } from '../network'

export class AvatarService {
  static async getAvataService(id: string): Promise<AxiosResponse<Screen[]>> {
    return await Network.getWithToken<Screen[]>({
      url: '/screens',
      params: { categoryId: id },
    })
  }

  static async UpdateAvatar(
    id: string,
    data: FormData,
  ): Promise<AxiosResponse<Screen[]>> {
    return await Network.putWithToken<Screen[]>({
      url: '/player',
      data: data,
      params: { categoryId: id },
    })
  }
}
