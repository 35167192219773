export const styles = {
  bodyComponent: {
    padding: 10,
    minHeight: '100vh',
  },

  headerComponent: {
    borderBottom: '1px solid #e0e0e0',
    padding: '10px 0',
  },

  card: {
    marginTop: 10,
  },

  listTitle: {
    fontSize: 16,
    color: '#212121',
  },

  menuItem: {
    //fontSize: 18
  },

  menuIcon: {
    //fontSize: 18
  },

  imgAvatarCard: {
    backgroundColor: '#e0e0e0',
    with: 120,
    height: 120,
    borderRadius: '50%',
    marginRight: 50,
  },

  cardInner: {
    marginBottom: 20,
  },
}
