import { AxiosResponse } from 'axios'
import { Message } from '../../Models'
import { Network } from '../network'

export class MessageService {
  static async getMessageService(
    id: string,
  ): Promise<AxiosResponse<Message[]>> {
    return await Network.getWithToken<Message[]>({
      url: '/messages',
      params: { screenId: id },
    })
  }
}

export class PostMessageService {
  static async postMessageService(
    data: FormData,
  ): Promise<AxiosResponse<Message>> {
    return await Network.postWithToken<Message>({
      url: '/messages',
      data: data,
    })
  }
}

export class PutMessageService {
  static async putMessageService(
    data: FormData,
  ): Promise<AxiosResponse<Message>> {
    return await Network.putWithToken<Message>({
      url: '/messages',
      data: data,
    })
  }
}

export class DeleteMessageService {
  static async deleteMessageService(
    id: string,
  ): Promise<AxiosResponse<Message>> {
    return await Network.deleteWithToken<Message>({
      url: '/messages/' + id,
    })
  }
}
