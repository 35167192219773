import moment from "moment";
export const formatDate = (dt: Date | undefined): string => {
  try {
    if (!dt) return "";
    return moment(dt).format("DD/MM/yyyy");
  } catch (error) {
    return "";
  }
};
export const formatTime = (dt: Date | undefined): string => {
  try {
    if (!dt) return "";
    return moment(new Date(dt)).format("DD/MM/yyyy HH:mm:ss");
  } catch (error) {
    return "";
  }
};
export const diff = (dtStart: Date, dtEnd: Date): string => {
  try {
    const start = moment(dtStart);
    const end = moment(dtEnd);
    const diff = end.diff(start);
    // execution
    return moment.utc(diff).format("HH:mm:ss");
  } catch (error) {
    return "";
  }
};
