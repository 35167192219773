import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input, message, Select } from "antd";
import { EditOutlined, UserAddOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";
import { generateCode } from "../../utils/numberToString";
import { RootState } from "../../store/reducers/rootReducer";
import { BASE_URL, REF_PROFIL_TYPE } from "../../utils/Constants";
import axios from "axios";
const { Option } = Select;
const { Search } = Input;

export default function ModalUser(props: any) {
  const { editUser, initUsers } = props;
  const { user } = useSelector((state: RootState) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!form || !editUser) return;
    form.setFieldsValue({
      ...editUser,
      password: null,
    });
  }, [editUser, form]);

  useEffect(() => {
    if (!form) return;
    form.setFieldsValue({
      profile: "research",
    });
  }, [form]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleValidate = async (values: any) => {
    try {
      if (!user) return;
      const data = { ...values, createdby: user?._id, disabled: false };
      if (editUser && editUser._id) {
        await axios.patch(BASE_URL + "/admin-users", {
          ...data,
          _id: editUser._id,
        });
      } else {
        await axios.post(BASE_URL + "/admin-users", data);
      }

      await initUsers();
      message.success(
        editUser && editUser._id
          ? "Modification avec succès"
          : "Ajout avec succès!"
      );
      form.resetFields();
      handleCancel();
    } catch (error: any) {
      console.log(error);
      let msg = "";
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = "Une erreur est survenue. Veuillez réessayer";
      }
      message.error(msg);
    }
  };
  const handleGen = () => {
    try {
      if (!form) return;
      form.setFieldsValue({
        password: generateCode(8),
      });
    } catch (error) {
      console.error(error);
    }
  };
  if (!user) return <></>;
  return (
    <>
      <Button
        size="small"
        type="primary"
        onClick={() => showModal()}
        icon={editUser ? <EditOutlined /> : <UserAddOutlined />}
      >
        {editUser ? "Modifier" : "Ajouter"}
      </Button>
      <Modal
        forceRender
        title="Ajouter un utilisateur"
        visible={isModalOpen}
        cancelText="Fermer"
        onCancel={() => handleCancel()}
        okButtonProps={{ style: { display: "none" } }}
      >
        <Form form={form} onFinish={handleValidate} layout="vertical">
          <Form.Item
            name="username"
            label="Nom d'utilisateur"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner le nom d'utilisateur.",
              },
              { max: 100, message: "Maximum 100 caractères autorisés" },
            ]}
          >
            <Input disabled={editUser && editUser._id} />
          </Form.Item>
          {!editUser && (
            <Form.Item
              name="password"
              label="Mot de passe"
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner le mot de passe",
                },
                { max: 20, message: "Maximum 20 caractères autorisés" },
              ]}
            >
              <Search
                placeholder="mot de passe"
                allowClear
                enterButton="Générer mot de passe"
                onSearch={handleGen}
              />
            </Form.Item>
          )}

          <Form.Item
            name="firstname"
            label="Prénom"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner le prénom.",
              },
              { max: 100, message: "Maximum 100 caractères autorisés" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastname"
            label="Nom"
            rules={[
              {
                required: true,
                message: "Veuillez renseigner le nom.",
              },
              { max: 100, message: "Maximum 100 caractères autorisés" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="profile"
            label="Profil d'utilisateur"
            rules={[
              {
                required: true,
                message: "Sélectionner le profil",
              },
            ]}
          >
            <Select
              onChange={(val) =>
                form.setFieldsValue({
                  profile: val,
                })
              }
              style={{ minWidth: 200 }}
              placeholder="profil d'utilisateur"
            >
              {REF_PROFIL_TYPE.map((ex: any) => (
                <Option key={ex.code} value={ex.code}>
                  {ex.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Valider
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
