import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store/store";
import { ErrorBoundary } from "react-error-boundary";
import ErrorAppFallback from "./pages/error/ErrorAppFallback";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <ErrorBoundary
      FallbackComponent={ErrorAppFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={(error, errorInfo) => console.error({ error, errorInfo })}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </BrowserRouter>
);

reportWebVitals();
