import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Modal, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import { useEffect, useState } from "react";
import { Contact } from "../Models";
import { url } from "../Models/urls";
import { acceptedImages } from "../utils/AccepedImage";
import { ContactSevice } from "../services/Contacts/ContactSevice";
import { Network } from "../services";
import {
  PostNewFeedSevice,
  PutNewFeedSevice,
} from "../services/NewFeed/NewFeedSevice";
import { attachImageWidth, MAX_File_Size } from "../utils/Constants";

interface Props {
  isVisible: boolean;
  closeModal: () => void;
  getNewFeeds: () => void;
  newfeed?: any;
  npcId?: string;
}

const { Option } = Select;

export const UpsertNewFeed = (props: Props) => {
  const { isVisible, closeModal, getNewFeeds, newfeed, npcId } = props;
  const [form] = useForm();
  const [npcs, setNpcs] = useState<Contact[]>([]);
  const [loadding, setLoadding] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageLink, setImageLink] = useState("");
  const [imageFile, setImageFile] = useState<File>();

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    //check images type
    setFileList(newFileList);
    newFileList.length > 0 &&
      newFileList.forEach((item) => {
        if (item.type) {
          if (item.size && item.size < MAX_File_Size) {
            if (acceptedImages.includes(item.type)) {
              setFileList(newFileList);
            } else {
              Modal.error({
                title: "Attention",
                content: "Seuls les formats d'image sont acceptés",
                okText: "OK",
                cancelText: "",
              });
            }
          } else {
            newFileList.pop();
            Modal.error({
              title: "warning",
              content: "La taille maximum du fichier d'upload est de 5Mb",
            });
          }
        } else {
          setFileList(newFileList);
        }
      });
  };

  useEffect(() => {
    if (newfeed && isVisible) {
      const images = newfeed.image;
      const items: UploadFile[] = [];
      images.forEach((img: UploadFile) => {
        items.push({
          uid: "",
          name: "",
          url: url + img,
        });
      });

      if (newfeed.image && newfeed.image.length > 0) {
        setImageLink(url + newfeed.image[0]);
      } else {
        setImageLink("");
      }

      form.setFieldsValue({
        npcId: newfeed.npcId,
        caption: newfeed.caption,
        date: newfeed.date.split("T")[0],
        comments: newfeed.comment,
        likes: newfeed && newfeed.info ? newfeed.info[0] : 0,
      });
    } else {
      form.setFieldsValue({
        npcId: npcId,
      });
    }
  }, [newfeed, isVisible]);

  useEffect(() => {
    fetchNpc();
  }, []);

  const fetchNpc = async () => {
    try {
      const res = await ContactSevice.getContactService();
      if (Network.isSuccess(res.status)) {
        const data = res.data;
        const NPCs: Contact[] = [];

        data
          .filter((item: Contact) => item.isNPC)
          .forEach((item: Contact) => {
            NPCs.push(item);
          });
        NPCs.push({
          _id: "myId",
          image: "",
          name: "Player",
          phone: "",
          isNPC: false,
        });

        setNpcs(NPCs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handChangeImg = async (files: any) => {
    if (!acceptedImages.includes(files[0].type)) {
      Modal.error({
        title: "Attention",
        content: "Seuls les formats d'image sont acceptés",
        okText: "OK",
      });
    } else {
      if (files[0].size < MAX_File_Size) {
        const imgLink = URL.createObjectURL(files[0]);
        setImageLink(imgLink);

        const img = new Image();
        img.src = URL.createObjectURL(files[0]);
        img.onload = function () {
          const canvas = document.createElement("canvas");
          //get width and height for calc ratio
          let width = img.width;
          let height = img.height;
          const ratio = attachImageWidth / width;
          //canvas.setAttribute('style', 'object-fit: cover')
          canvas.width = width * ratio;
          canvas.height = height * ratio;
          let ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, width * ratio, height * ratio);
          canvas.toBlob(
            function (blob) {
              if (blob) {
                const newImage = new File(
                  [blob],
                  files[0].name.split(".")[0] + ".jpeg"
                );
                setImageFile(newImage);
              }
            },
            "image/jpeg",
            0.8
          );
        };

        //setFileImage(newResizedFile)
      } else {
        Modal.confirm({
          title: "Warning",
          content: "La taille maximum du fichier d'upload est de 5MB",
        });
      }
    }
  };
  const handleSubmit = async (values: any) => {
    //setLoadding(true)
    const data = new FormData();

    //images

    if (imageFile) {
      data.append("files", imageFile);
    } else {
      data.append("image", imageLink.split(url)[1]);
    }

    // if (values.images) {
    //   const files = values.images.fileList
    //   files.forEach((file: any) => {
    //     if (file.url) {
    //       data.append('image', file.url.split(url)[1])
    //     } else {
    //       data.append('files', file.originFileObj)
    //     }
    //   })
    // } else {
    //   //undifined
    //   if (newfeed.image) {
    //     //have image & edit

    //     newfeed.image &&
    //       newfeed.image.forEach((img: string) => {
    //         data.append('image', img)
    //       })
    //   }
    // }
    const info: string[] = [];
    info.push(values.likes);
    data.append("info", JSON.stringify(info));
    data.append("caption", values.caption);
    data.append("npcId", values.npcId);
    data.append("date", values.date);

    data.append(
      "comment",
      JSON.stringify(!values.comments ? [] : values.comments)
    );

    newfeed && data.append("_id", newfeed._id);

    try {
      if (newfeed) {
        await PutNewFeedSevice.putNewFeed(data);
      } else {
        PostNewFeedSevice.postutNewFeed(data);
      }
      setLoadding(false);
      console.log("Done");
      message.success("Fil d'actualité ajouté avec succès");
      handleCloseModal();
      getNewFeeds();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    form.resetFields();
    setFileList([]);
    closeModal();
    getNewFeeds();
  };

  const handeRemoveImage = () => {
    setImageFile(undefined);
    setImageLink("");
  };

  return (
    <Modal
      forceRender
      title={newfeed ? "Modification" : "Nouveau"}
      footer={null}
      width={768}
      visible={isVisible}
      onCancel={() => handleCloseModal()}
    >
      <div className="text-center" style={{ marginBottom: 20 }}>
        <label htmlFor="imgInputNewFeed" style={{ width: "100%" }}>
          <img
            src={
              imageLink !== ""
                ? imageLink
                : "https://img.icons8.com/ios-filled/150/e0e0e0/stack-of-photos--v1.png"
            }
            style={{ width: "50%", height: "auto", objectFit: "cover" }}
          />
          <p className="text-muted" style={{ marginTop: 10 }}>
            Sélectionner une image
          </p>
        </label>
        {imageLink !== "" ? (
          <button className="btn btn-sm btn-light" onClick={handeRemoveImage}>
            <i className="fas fa-trash text-muted"></i> Supprimer l'image
          </button>
        ) : null}
      </div>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="images" className="d-none">
          <Input
            id="imgInputNewFeed"
            placeholder="afsj"
            type="file"
            accept={acceptedImages.toString()}
            style={{ display: "none" }}
            onChange={(e) => handChangeImg(e.target.files)}
          />
        </Form.Item>

        <Row>
          <Col flex={1} style={{ marginRight: 10 }}>
            <Form.Item
              name="npcId"
              label="Peronnage du jeu"
              rules={[
                {
                  required: true,
                  message: "sélectionner une personnage",
                },
              ]}
            >
              <Select>
                {npcs &&
                  npcs.map((npc) => (
                    <Option key={npc._id} value={npc._id}>
                      {npc.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner une date",
                },
              ]}
              name="date"
              label="Date de publication"
            >
              <Input placeholder="" type="date" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name="likes" label="Likes" initialValue={0}>
              <Input
                style={{ width: "100%" }}
                maxLength={9}
                showCount
                type="text"
                placeholder="Likes"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="caption" label="Caption">
          <Input.TextArea rows={5} placeholder="add caption..." size="large" />
        </Form.Item>
        <label>Ajouter des commentaires</label>
        <Form.List name="comments">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  key={key}
                  style={{
                    border: "1px dashed #676767",
                    margin: "20px 0",
                    padding: 10,
                  }}
                >
                  <Row>
                    <Col key={key} flex={1}>
                      <Form.Item
                        label="Nom d'utilisateur"
                        name={[name, "userName"]}
                        rules={[
                          {
                            required: true,
                            message: "Veuillez renseigner l'utilisateur",
                          },
                        ]}
                      >
                        <Input placeholder="nom d'utilisateur" />
                      </Form.Item>
                    </Col>
                    <Col
                      flex={2}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Button
                        type="primary"
                        danger
                        icon={<MinusCircleOutlined />}
                        onClick={() => remove(name)}
                      ></Button>
                    </Col>
                  </Row>
                  <Form.Item
                    name={[name, "content"]}
                    label="Contenu du commentaire"
                  >
                    <Input.TextArea rows={3} placeholder="commentaire" />
                  </Form.Item>
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Nouveau commentaire
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button
            loading={loadding}
            type="primary"
            onClick={() => form.submit()}
          >
            {newfeed ? "Modifier" : "Valider"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
