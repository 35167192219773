export const catNameFrance = [
  {
    _id: '628f1e1fd8abd3c9f8f3d55a',
    categoryName: "Gestion d'avatars",
  },
  {
    _id: '628f1fb0d8abd3c9f8f3d55c',
    categoryName: 'Groupe chat principal',
  },
  {
    _id: '628f2ac85d4260b2ed500ed1',
    categoryName: 'Application Messages',
  },
  {
    _id: '628f2d1a5d4260b2ed500ed6',
    categoryName: 'Application Contacts',
  },
  {
    _id: '628f2e8c5d4260b2ed500ed8',
    categoryName: 'Application Notes',
  },
  {
    _id: '629430adeab20b4ffc4e6273',
    categoryName: 'Application Map',
  },
  {
    _id: '62981a19fc1c42bf31c6e007',
    categoryName: 'Application Pixagram',
  },
  {
    _id: '6299d81153e46f0b3070b53f',
    categoryName: 'Fichiers téléchargés',
  },
  {
    _id: '629eaf0c95b199f59b757e40',
    categoryName: "nouvelle fil d'actualité Pixagram",
  },
]
