import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { Button, Result } from "antd";
 
export default function Page401() {
  useEffect(() => {
    const timeout = setTimeout(() => {
      const button = document.getElementById("btn-path-login");
      if (button) {
        button.click();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <Result
      status="error"
      title="401"
      subTitle=" Désolé, la page est protégée."
      extra={
        <Button href="/" type="link" id="btn-path-login">
          Retourner à l'accueil
        </Button>
      }
    />
  );
}
