import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { IPost } from "../../../Models/IPost";
import { LoginPayload } from "../../../Models/Payload/LoginPayload";
import { url } from "../../../Models/urls";
import {
  fetchAuthFailure,
  fetchAuthSuccess,
  logoutRequest,
} from "../../actions/authActions/authActions";
import { authTypes } from "../../actionTypes/authTypes";
import { ResponseGenerator } from "../type";
import { Network } from "../../../services";
import { Account } from "../../../Models";
const login = (payload: LoginPayload) => axios.post(url + "login", payload);
const getAccount = (token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return axios.get(url + "account");
};

function* fetchAuthSaga({ payload }: any) {
  try {
    const response: ResponseGenerator = yield call(() => login(payload));
    if (response.status === 200) {
      const resAcc: ResponseGenerator = yield call(() =>
        getAccount(response.data.token)
      );
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("username", payload.username);
      localStorage.setItem(
        "expirationTime",
        (new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toString()
      );
      yield put(
        fetchAuthSuccess({
          isAuthenticated: true,
          user: { ...resAcc.data },
        })
      );
    } else {
      yield put(
        fetchAuthFailure({
          error: response.data.message,
        })
      );
    }
  } catch (e) {
    console.log(e);
    // Add this because Prettier auto remove 'any' if format
    const error: any = e;
    yield put(
      fetchAuthFailure({
        error: error.response?.data?.message ?? error.message,
      })
    );
  }
}

function* checkAuthSaga() {
  try {
    const auth = localStorage.getItem("isAuthenticated");
    const token = localStorage.getItem("token");
    const resAcc: ResponseGenerator = yield call(() =>
      getAccount(String(token))
    );
    if (resAcc.status === 200) {
      localStorage.setItem("isAuthenticated", "true");
      yield put(
        fetchAuthSuccess({
          isAuthenticated: true,
          user: { ...resAcc.data },
        })
      );
    } else {
      yield put(
        fetchAuthFailure({
          error: resAcc.data.message,
        })
      );
    }
  } catch (e) {
    const error: any = e;
    yield put(
      fetchAuthFailure({
        error: error.response?.data?.message ?? error.message,
      })
    );
  }
}

function* logoutSaga() {
  try {
    delete axios.defaults.headers.common.Authorization;
    localStorage.clear();
    logoutRequest();
  } catch (e) {
    const error: any = e;
    yield put(
      fetchAuthFailure({
        error: error.response?.data?.message ?? error.message,
      })
    );
  }
}

function* authSaga() {
  yield all([
    takeLatest(authTypes.FETCH_AUTH_REQUEST, fetchAuthSaga),
    takeLatest(authTypes.CHECK_AUTH_REQUEST, checkAuthSaga),
    takeLatest(authTypes.LOGOUT_REQUEST, logoutSaga),
  ]);
}

export default authSaga;
