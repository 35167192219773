import axios, { AxiosPromise, AxiosResponse } from 'axios'
import { BASE_URL } from '../utils/Constants'

interface RequestProp {
  url: string
  data?: any
  params?: any
}

export class Network {
  static isSuccess(status: number) {
    const successStatusList = [200, 201, 202, 203, 204]
    return successStatusList.includes(status)
  }

  static async getWithToken<T>(
    props: RequestProp,
  ): Promise<AxiosResponse<any>> {
    const { url, params } = props
    const token = localStorage.getItem('token')
    return await axios.get<T>(BASE_URL + url, {
      params,
      headers: { Authorization: 'Bearer ' + token },
    })
  }

  static async postWithToken<T>(
    props: RequestProp,
  ): Promise<AxiosResponse<any>> {
    const { url, data, params } = props
    const token = localStorage.getItem('token')
    return await axios.post<T>(BASE_URL + url, data, {
      params,
      headers: { Authorization: 'Bearer ' + token },
    })
  }

  static async putWithToken<T>(
    props: RequestProp,
  ): Promise<AxiosResponse<any>> {
    const { url, data, params } = props
    const token = localStorage.getItem('token')
    return await axios.put<T>(BASE_URL + url, data, {
      params,
      headers: { Authorization: 'Bearer ' + token },
    })
  }

  static async deleteWithToken<T>(
    props: RequestProp,
  ): Promise<AxiosResponse<any>> {
    const { url, params } = props
    const token = localStorage.getItem('token')
    return await axios.delete<T>(BASE_URL + url, {
      params,
      headers: { Authorization: 'Bearer ' + token },
    })
  }
}
