import { Avatar, Card, List, Button } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HeaderContent } from "../components/HeaderContent";
import { UpsertProfile } from "../modals/UpsertProfile";
import { Profile } from "../Models";

import { Network } from "../services";
import { ProfilesSevice } from "../services/Profile/ProfileSevice";
import { BASE_URL } from "../utils/Constants";

export const ProfilePage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [persions, setPersions] = useState<Profile[]>([]);

  useEffect(() => {
    getProfile();
  }, []);

  const onCloseModal = () => {
    setIsVisible(false);
  };

  const getProfile = async () => {
    const res = await ProfilesSevice.getPorfilesService();
    if (Network.isSuccess(res.status)) {
      setPersions(res.data);
    }
  };

  return (
    <div>
      <HeaderContent
        title="Les profils Pixagram"
        button={
          <Button
            onClick={() => setIsVisible(true)}
            type="primary"
            icon={<UserAddOutlined />}
            key="profile"
          >
            Nouveau profil
          </Button>
        }
      />

      <List
        itemLayout="vertical"
        size="large"
        dataSource={persions}
        renderItem={(item) => (
          <Link to={`/admin/persional-detail/${item._id}`} key={item._id}>
            <Card style={{ margin: "10px 0" }}>
              <List.Item
                actions={
                  item.info
                    ? [
                        <p key="1">Star {item.info[0]}</p>,
                        <p key="2">Following {item.info[1]}</p>,
                        <p key="3">Follower {item.info[2]}</p>,
                      ]
                    : []
                }
                style={{ padding: 0 }}
                // extra={
                //   <img
                //     src={
                //       item.imageList ? BASE_URL + '/' + item.imageList[0] : ''
                //     }
                //     alt={item.imageList[0]}
                //   />
                // }
              >
                <List.Item.Meta
                  avatar={
                    item.avatar ? (
                      <Avatar src={BASE_URL + "/" + item.avatar} />
                    ) : null
                  }
                  title={item.profileName}
                  description={item.status}
                />
                {item.status}
              </List.Item>
            </Card>
          </Link>
        )}
      ></List>

      <UpsertProfile
        visible={isVisible}
        onCancel={onCloseModal}
        getProfile={getProfile}
      />
    </div>
  );
};
