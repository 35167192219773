import {
  Button,
  Checkbox,
  Card,
  Form,
  Input,
  Layout,
  List,
  message,
  Modal,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { styles } from "../styles/styles";
import { Category, Screen } from "../Models";
import { useForm } from "antd/lib/form/Form";
import { EditFilled } from "@ant-design/icons";
import { HeaderContent } from "../components/HeaderContent";
import {
  DeleteScreenSevice,
  PostScreenSevice,
  PutScreenSevice,
  ScreenSevice,
} from "../services/Screen/ScreenSevice";
import { Network } from "../services/network";
import { CategoryService } from "../services";
import { CONST_SCREENS } from "../utils/const-screens";
import { getCatFranceName } from "../utils/get_cat_france_name";

const { Content } = Layout;

export const Notifications = () => {
  const [screens, setScreens] = useState<Screen[]>([]);
  const [isVisibleModalAddScreen, setIsVisibleModalAddScreen] = useState(false);
  const [cat, setCat] = useState<Category>();
  const [isEdit, setIsEdit] = useState(false);
  const [screen, setScreen] = useState<Screen>();
  const [loadding, setLoadding] = useState(false);

  const { id } = useParams();
  const [form] = useForm();

  useEffect(() => {
    id && fetchScreens(id);
  }, [id]);

  const fetchScreens = async (id: string) => {
    try {
      const res = await ScreenSevice.getScreenService("/screens", id);
      if (Network.isSuccess(res.status)) {
        setScreens(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    id && fetchCats(id);
  }, [id]);

  const fetchCats = async (id: string) => {
    const res = await CategoryService.getCategories();
    if (Network.isSuccess(res.status)) {
      res.data.forEach((item) => {
        if (item._id === id) {
          setCat(item);
        }
      });
    }
  };

  const showModalAddScreen = () => {
    setIsVisibleModalAddScreen(true);
    setIsEdit(false);
  };

  const handlePostNewScreen = async (values: any) => {
    setLoadding(true);

    let data = {};

    if (screen) {
      data = {
        _id: screen._id,
        categoryId: id,
        screenName: values.screenName,
        title: values.title,
        isPrivacy: values.isPrivacy,
      };
    } else {
      data = {
        categoryId: id,
        screenName: values.screenName,
        title: values.title,
        isPrivacy: values.isPrivacy,
      };
    }

    try {
      if (isEdit) {
        await PutScreenSevice.putScreenSevice(data);
      } else {
        await PostScreenSevice.postScreenSevice(data);
      }

      form.resetFields();
      setIsVisibleModalAddScreen(false);
      id && fetchScreens(id);

      console.log("Done");
      setLoadding(false);
      message.success(isEdit ? "Mise à jour" : "Création" + " avec succès");
    } catch (error) {
      console.log(error);
    }
  };

  const closeModalAddScreen = () => {
    form.resetFields();
    setIsVisibleModalAddScreen(false);
  };

  const handleDelScreen = async (itemId: string) => {
    try {
      Modal.confirm({
        title: "Suppression de message",
        content: "Confirmez-vous la suppression?",
        onOk: () => {
          DeleteScreenSevice.deleteScreenSevice(itemId).then(() => {
            id && fetchScreens(id);
            message.success("Suppression avec succès!");
          });
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditScreen = (item: Screen) => {
    setIsVisibleModalAddScreen(true);
    form.setFieldsValue(item);

    setIsEdit(true);
    setScreen(item);
  };

  return (
    <Content style={styles.bodyComponent}>
      <HeaderContent
        title={getCatFranceName(id!)}
        button={
          id === "628f2ac85d4260b2ed500ed1" ? (
            //open button add new at Leo-Message
            <Button type="primary" onClick={showModalAddScreen} key="button">
              Nouveau
            </Button>
          ) : null
        }
      />

      <Card style={styles.card}>
        <List>
          {screens.map((screen, index) =>
            screen.screenName === "etape3FindLove" ||
            screen.screenName === "etape5GameEnd" ? null : (
              <List.Item
                key={index}
                actions={[
                  <Button
                    key="btnEdit"
                    type="text"
                    icon={<EditFilled />}
                    onClick={() => handleEditScreen(screen)}
                  ></Button>,
                  id === "628f2ac85d4260b2ed500ed1" &&
                  !CONST_SCREENS.includes(screen._id) ? (
                    <Button
                      icon={<i className="fas fa-trash-alt text-danger"></i>}
                      type="text"
                      danger
                      onClick={() => handleDelScreen(screen._id)}
                    ></Button>
                  ) : null,
                ]}
              >
                <List.Item.Meta
                  title={
                    <Link
                      to={
                        "/admin/screen-detail/" +
                        screen._id +
                        "/" +
                        screen.title
                      }
                      style={styles.listTitle}
                    >
                      {screen.title}
                    </Link>
                  }
                  description={screen.screenName}
                />
              </List.Item>
            )
          )}
        </List>
      </Card>

      <Modal
        getContainer={false}
        footer={null}
        title={isEdit ? "Modification" : "Nouveau"}
        visible={isVisibleModalAddScreen}
        closable={true}
        onCancel={closeModalAddScreen}
      >
        <Form form={form} layout="vertical" onFinish={handlePostNewScreen}>
          <Form.Item
            label="Libellé du message"
            name="screenName"
            rules={[
              {
                required: true,
                message: "Libellé",
              },
            ]}
          >
            <Input disabled={isEdit} placeholder="Libellé" />
          </Form.Item>
          <Form.Item label="Titre" name="title">
            <Input placeholder="Titre" />
          </Form.Item>
          <Form.Item
            name="isPrivacy"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Est ce bien un message privé ?</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button loading={loadding} type="primary" htmlType="submit">
              {screen && isEdit ? "Modifier" : "Valider"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Content>
  );
};
