import { Button, Checkbox, Form, Input, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'
import { Local } from '../Models'
import { url } from '../Models/urls'
import {
  PostLocationSevice,
  PutLocationSevice,
} from '../services/Location/LocationSevice'
import { acceptedImages } from '../utils/AccepedImage'
import { attachImageWidth, MAX_File_Size } from '../utils/Constants'

interface Props {
  location?: Local
  isVisible: boolean
  closeModal: () => void
  getLocations: () => {}
}

export const UpsertLocationModal = (props: Props) => {
  const { isVisible, location, closeModal, getLocations } = props
  const [imgUrl, setImgUrl] = useState('')
  const [form] = useForm()
  const [loadding, setLoadding] = useState(false)
  const [fileImage, setFileImage] = useState<any>()

  useEffect(() => {
    if (location && isVisible) {
      form.setFieldsValue({
        title: location.title,
        additionDetail: location.additionDetail,
        location: location.content,
        isCorrect: location.isCorrect,
      })

      if (location.image) {
        setImgUrl(url + location.image)
      }
    }
  }, [location, isVisible])

  const handChangeImg = async (files: any) => {
    if (!acceptedImages.includes(files[0].type)) {
      Modal.error({
        title: 'Attention',
        content: "Seuls les formats d'image sont acceptés",
        okText: 'OK',
      })
    } else {
      if (files[0].size < MAX_File_Size) {
        const imgLink = URL.createObjectURL(files[0])
        setImgUrl(imgLink)

        const img = new Image()
        img.src = URL.createObjectURL(files[0])
        img.onload = function () {
          const canvas = document.createElement('canvas')
          //get width and height for calc ratio
          let width = img.width
          let height = img.height
          const ratio = attachImageWidth / width
          //canvas.setAttribute('style', 'object-fit: cover')
          canvas.width = width * ratio
          canvas.height = height * ratio
          let ctx = canvas.getContext('2d')
          ctx?.drawImage(img, 0, 0, width * ratio, height * ratio)
          canvas.toBlob(
            function (blob) {
              if (blob) {
                const newImage = new File(
                  [blob],
                  files[0].name.split('.')[0] + '.jpeg',
                )
                setFileImage(newImage)
              }
            },
            'image/jpeg',
            0.8,
          )
        }

        //setFileImage(newResizedFile)
      } else {
        Modal.confirm({
          title: 'Attention',
          content: 'Taille maximum du fichier upload est de 5MB',
        })
      }
    }
  }

  const handlePostLocation = async (values: any) => {
    //setLoadding(true)
    const data = new FormData()

    if (fileImage) {
      data.append('file', fileImage)
    } else {
      if (imgUrl) {
        data.append('image', imgUrl.split(url)[1])
      }
    }
    data.append('isLocation', 'true')
    if (location) {
      data.append('_id', location._id)
    }

    data.append('title', values.title)
    data.append('content', values.location ?? '')
    data.append('additionDetail', values.additionDetail ?? '')
    data.append('isCorrect', values.isCorrect)

    try {
      if (location) {
        await PutLocationSevice.putLocalService(data)
      } else {
        await PostLocationSevice.postLocalService(data)
      }
      setLoadding(false)
      getLocations()
      console.log('Done')

      handleCloseModal()
      setImgUrl('')
      form.resetFields()
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseModal = () => {
    form.resetFields()
    setImgUrl('')
    closeModal()
  }

  const handeRemoveImage = () => {
    setFileImage(undefined)
    setImgUrl('')
  }
  return (
    <Modal
      forceRender
      title={location ? 'Modification' : 'Nouvelle'}
      footer={null}
      visible={isVisible}
      onCancel={() => handleCloseModal()}
    >
      <div className="text-center">
        <label htmlFor="imgInput" style={{ width: '100%' }}>
          <img
            src={
              imgUrl !== url
                ? imgUrl
                : require('../assets/images/default-thumbnail.jpg')
            }
            style={{ width: '50%', height: 'auto', objectFit: 'cover' }}
          />
          <p className="text-muted" style={{ marginTop: 10 }}>
            Sélectionner une image
          </p>
        </label>
        <button className="btn btn-sm btn-light" onClick={handeRemoveImage}>
          <i className="fas fa-trash text-muted"></i> Supprimer l'image
        </button>
      </div>

      <Form
        form={form}
        layout="vertical"
        name="address"
        onFinish={handlePostLocation}
      >
        <Form.Item name="image">
          <Input
            id="imgInput"
            placeholder="afsj"
            type="file"
            accept={acceptedImages.toString()}
            style={{ display: 'none' }}
            onChange={(e) => handChangeImg(e.target.files)}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Veuillez renseigner la libellé de l'adresse",
            },
          ]}
          name="title"
          label="Libellé"
        >
          <Input style={{ fontWeight: 'bold' }} placeholder="Adresse" />
        </Form.Item>
        <Form.Item name="additionDetail" label="Informations complémentaire">
          <Input placeholder="description" />
        </Form.Item>
        <Form.Item name="location" label="Location">
          <Input placeholder="Adresse" />
        </Form.Item>
        <Form.Item
          name="isCorrect"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox>
            Est ce bien l'adresse valide du laboratoire de Léo ?
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button loading={loadding} type="primary" htmlType="submit">
            {location ? 'Modifier' : 'Valider'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
