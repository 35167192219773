import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import { useEffect, useState } from "react";
import { Contact, Message } from "../Models";
import { url } from "../Models/urls";
import {
  PostMessageService,
  PutMessageService,
} from "../services/Message/MessageSevice";
import { acceptedImages } from "../utils/AccepedImage";
import { attachImageWidth, MAX_File_Size } from "../utils/Constants";

interface Props {
  mes?: Message;
  isVisible: boolean;
  closeModal: () => void;
  getMessage: () => void;
  npcs: Contact[];
  screenId?: string;
}

const { Option } = Select;

export const UpserMessageModal = (props: Props) => {
  const [form] = useForm();
  const { mes, closeModal, isVisible, npcs, getMessage, screenId } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [npcId, setNpcId] = useState<string>("myId");
  const [isDisable, setIsDisable] = useState(false);
  const [imageLink, setImageLink] = useState("");
  const [imageFile, setImageFile] = useState<File>();

  const handleCloseModal = () => {
    form.resetFields();
    setImageFile(undefined);
    setImageLink("");

    setFileList([]);
    getMessage();
    closeModal();
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      newFileList.forEach((item) => {
        if (item.size && item.size < MAX_File_Size) {
          if (item.type) {
            if (acceptedImages.includes(item.type)) {
              setFileList(newFileList);
            } else {
              Modal.error({
                title: "Attention",
                content: "Seuls les format d'image sont acceptés",
                okText: "OK",
                cancelText: "",
              });
            }
          }
        } else {
          Modal.error({
            title: "Attention",
            content: "Max 5MB",
          });
        }
      });
    } else {
      setFileList(newFileList);
    }
  };

  useEffect(() => {
    if (mes) {
      setIsDisable(true);
      setNpcId(mes.npcId);

      let timeValue = "";
      if (mes.date) {
        timeValue = mes.date.split("T")[0];
      }

      if (mes.image.length > 0) {
        setImageLink(url + mes.image);
      } else {
        setImageLink("");
      }

      form.setFieldsValue({
        npc: mes.npcId,
        text: mes.text,
        date: timeValue,
        playerReply: mes.text.split("##")[0],
        playerHelp: mes.text.split("##")[1],
        helpContent: mes.helpContent,
        isUnlockStep: mes.isUnlockStep,
      });

      if (mes.image.length > 0) {
        const images: UploadFile[] = [];
        images.push({
          uid: "",
          name: "",
          url: url + mes.image,
        });
        setFileList(images);
      }
    }
  }, [mes]);

  const handleAddMessage = async (values: any) => {
    //console.log(text.substring(0, text.length - 2))

    const data = new FormData();
    data.append("screenId", "" + screenId);
    if (mes) {
      data.append("_id", mes._id);
    }
    data.append(
      "text",
      mes?.npcId === "myId"
        ? values.playerReply + "##" + values.playerHelp
        : values.text
    );
    data.append("helpContent", values.helpContent);
    data.append("npcId", values.npc);
    if (values.date) {
      data.append("date", values.date);
    }

    if (imageFile) {
      imageFile && data.append("file", imageFile);
    } else {
      if (imageLink) {
        data.append("image", imageLink.split(url)[1]);
      }
    }

    if (values.isUnlockStep) {
      data.append("isUnlockStep", values.isUnlockStep);
    } else {
      data.append("isUnlockStep", "false");
    }
    onAddMessage(data);

    // if (mes?.isUnlockStep) {
    //   if (fileList.length === 0) {
    //     Modal.warning({
    //       title: 'Warning',
    //       content:
    //         'You need at least one image to unlock step find lab correct',
    //     })
    //   } else {
    //     onAddMessage(data)
    //   }
    // } else {
    //   onAddMessage(data)
    // }
  };

  const onAddMessage = async (data: FormData) => {
    try {
      if (mes) {
        await PutMessageService.putMessageService(data);
      } else {
        await PostMessageService.postMessageService(data);
      }
      setIsLoading(false);
      handleCloseModal();
      getMessage();
      message.success(mes ? "Modification " : "Ajout" + " réussi(e)");
    } catch (error) {
      const e: any = error;
      Modal.error({
        title: "Error",
        content: e.response.data.message,
      });
    }
  };

  const handleSetCurrentDate = (value: string) => {
    setCurrentDate(value);
  };

  const onChangeNPCTalk = (value: string) => {
    setNpcId(value);
  };

  const handChangeImg = async (files: any) => {
    if (!acceptedImages.includes(files[0].type)) {
      Modal.error({
        title: "Attention",
        content: "Seuls les formats d'image sont acceptés",
        okText: "OK",
      });
    } else {
      if (files[0].size < MAX_File_Size) {
        const imgLink = URL.createObjectURL(files[0]);
        setImageLink(imgLink);

        const img = new Image();
        img.src = URL.createObjectURL(files[0]);
        img.onload = function () {
          const canvas = document.createElement("canvas");
          //get width and height for calc ratio
          let width = img.width;
          let height = img.height;
          const ratio = attachImageWidth / width;
          //canvas.setAttribute('style', 'object-fit: cover')
          canvas.width = width * ratio;
          canvas.height = height * ratio;
          let ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, width * ratio, height * ratio);
          canvas.toBlob(
            function (blob) {
              if (blob) {
                const newImage = new File(
                  [blob],
                  files[0].name.split(".")[0] + ".jpeg"
                );
                setImageFile(newImage);
              }
            },
            "image/jpeg",
            0.8
          );
        };

        //setFileImage(newResizedFile)
      } else {
        Modal.confirm({
          title: "Warning",
          content: "You can not upload file bigger 5MB",
        });
      }
    }
  };

  const handleRemoveImage = () => {
    setImageFile(undefined);
    setImageLink("");
  };
  return (
    <Modal
      forceRender
      title={mes ? "Modification" : "Ajout d'un message"}
      footer={null}
      visible={isVisible}
      width={768}
      onCancel={handleCloseModal}
    >
      <Form layout="vertical" form={form} onFinish={handleAddMessage}>
        <div className="text-center" style={{ marginBottom: 20 }}>
          <label htmlFor="imgInput" style={{ width: "100%" }}>
            <img
              src={
                imageLink !== ""
                  ? imageLink
                  : "https://img.icons8.com/ios-filled/150/e0e0e0/stack-of-photos--v1.png"
              }
              style={{ width: "30%", height: "auto", objectFit: "cover" }}
            />
            <p className="text-muted" style={{ marginTop: 10 }}>
              Sélectionner une image
            </p>
          </label>
          {imageLink !== "" ? (
            <button
              className="btn btn-sm btn-light"
              onClick={handleRemoveImage}
            >
              <i className="fas fa-trash text-muted"></i> Supprimer l'image
            </button>
          ) : null}
        </div>

        <Form.Item name="image">
          <Input
            id="imgInput"
            placeholder="afsj"
            type="file"
            accept={acceptedImages.toString()}
            style={{ display: "none" }}
            onChange={(e) => handChangeImg(e.target.files)}
          />
        </Form.Item>

        {mes?.screenId === "6296d3c49bf60eb57f674708" && fileList.length > 0 ? (
          <Form.Item
            valuePropName="checked"
            name="isUnlockStep"
            initialValue={false}
          >
            <Checkbox disabled={mes?.isUnlockStep}>
              Image utilisée pour débloquer l'étape de recherche du laboratoire
            </Checkbox>
          </Form.Item>
        ) : null}

        <div className="row">
          <div className="col">
            <Form.Item
              name="npc"
              label="Sélectionner l'expéditeur"
              rules={[
                {
                  required: true,
                  message: "Sélectionner l'expéditeur",
                },
              ]}
              initialValue="select"
            >
              <Select
                placeholder="l'expéditeur"
                onChange={onChangeNPCTalk}
                style={{ minWidth: 200 }}
              >
                {npcs.map((npc) => (
                  <Option
                    key={npc._id}
                    value={npc._id}
                    style={{
                      marginTop: npc._id === "myId" ? 15 : "",
                    }}
                  >
                    {npc.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col">
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner la date.",
                },
              ]}
              initialValue={currentDate ? currentDate : ""}
            >
              <Input
                type="date"
                onChange={(e) => handleSetCurrentDate(e.target.value)}
              />
            </Form.Item>
          </div>
        </div>

        {npcId && npcId === "myId" ? (
          <>
            <b>Options</b>
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col">
                <label>Réponse: </label>
                <Form.Item
                  name="playerReply"
                  style={{ marginBottom: 20 }}
                  rules={[{ required: true, message: "Option manquante" }]}
                >
                  <Input maxLength={50} showCount placeholder="Option" />
                </Form.Item>
              </div>
              <div className="col">
                <label>Aider: </label>
                <Form.Item
                  name="playerHelp"
                  style={{ marginBottom: 20 }}
                  rules={[{ required: true, message: "Option manquante" }]}
                >
                  <Input maxLength={50} showCount placeholder="Option" />
                </Form.Item>
              </div>
            </div>

            <Form.Item
              style={{ marginTop: 10, fontWeight: "bold" }}
              name="helpContent"
              label="Contenu de l'aide"
            >
              <Input placeholder="Aide" />
            </Form.Item>
          </>
        ) : (
          <Form.Item name="text" label="Contenu" style={{ fontWeight: "bold" }}>
            <Input.TextArea placeholder="Contenu" rows={8} />
          </Form.Item>
        )}

        <Form.Item>
          <Space>
            <Button
              loading={isLoading}
              type="primary"
              onClick={() => form.submit()}
            >
              {mes ? "Modifier" : "Valider"}
            </Button>
            <Button onClick={() => form.resetFields()}>Réinitilaliser</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
