import { Button, Form, Input, message, Modal } from 'antd'
import axios from 'axios'
import { useState } from 'react'
import { token } from '../Models/token'
import { url } from '../Models/urls'

interface Props {
  visible: boolean
  onCancel: () => void
}

export const ChangePassword = (props: Props) => {
  const { visible, onCancel } = props
  const [form] = Form.useForm()
  const [isNotMatch, setIsNotMatch] = useState(false)

  const handleChangePassword = async (values: any) => {
    try {
      if (values.newPass === values.reNewPass) {
        //change pass
        const data = {
          username: localStorage.getItem('username'),
          oldPassword: values.currentPass,
          newPassword: values.newPass,
        }
        await axios({
          method: 'put',
          url: url + 'account',
          data: data,
          headers: { Authorization: 'Bearer ' + token },
        }).then(() => {
          console.log('Done')
          message.success('Update password successfuly')
          closeModal()
        })
      } else {
        setIsNotMatch(true)
      }
    } catch (error) {
      Modal.error({
        title: 'Error',
        content:
          'Authentication failed. Username/Password incorrect, please try again!',
      })
    }
  }

  const closeModal = () => {
    form.resetFields()
    onCancel()
    setIsNotMatch(false)
  }
  return (
    <Modal
      forceRender
      footer={null}
      visible={visible}
      onCancel={() => closeModal()}
      title="Change password"
    >
      <Form form={form} onFinish={handleChangePassword} layout="vertical">
        <Form.Item name="currentPass" label="Current password: ">
          <Input.Password
            size="large"
            prefix={
              <i
                className="fas fa-lock text-muted"
                style={{ marginRight: 10 }}
              ></i>
            }
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          validateStatus={isNotMatch ? 'error' : ''}
          help={isNotMatch ? 'password not match' : ''}
          name="newPass"
          label="New password: "
        >
          <Input.Password
            size="large"
            prefix={
              <i
                className="fas fa-lock-open text-muted"
                style={{ marginRight: 10 }}
              ></i>
            }
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          validateStatus={isNotMatch ? 'error' : ''}
          help={isNotMatch ? 'password not match' : ''}
          name="reNewPass"
          label="Confirm new password: "
        >
          <Input.Password
            size="large"
            prefix={
              <i
                className="fas fa-lock-open text-muted"
                style={{ marginRight: 10 }}
              ></i>
            }
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
