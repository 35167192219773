import {
  AppstoreAddOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Image, Button, Space, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { HeaderContent } from "../components/HeaderContent";
import { UpsertProfile } from "../modals/UpsertProfile";
import { UpsertStory } from "../modals/UpsertStory";
import { NewPost, Profile } from "../Models";
import { url } from "../Models/urls";
import { Network } from "../services";
import {
  DeleteNewPostService,
  NewPostService,
} from "../services/newPost/NewPostService";
import {
  ProfileSevice,
  DeleteProfileSevice,
} from "../services/Profile/ProfileSevice";
import { styles } from "../styles/styles";
import { NewFeedPixigram } from "./NewPeedPixigram";

export const ProfileDetail = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [isVisibleModalProfile, setIsVisibleModalProfile] = useState(false);
  const [profile, setProfile] = useState<Profile>();
  const [visibleStoryModal, setVisibleStoryModal] = useState(false);
  const [newposts, setNewposts] = useState<NewPost[]>([]);
  const [isEditInfo, setIsEditInfo] = useState(true);
  const [newPost, setNewPost] = useState<NewPost>();

  useEffect(() => {
    if (id) {
      getProfile(id);
      getNewPosts(id);
    }
  }, [id]);

  const getProfile = async (id: string) => {
    const res = await ProfileSevice.getProfileService("/profile/", id);
    if (Network.isSuccess(res.status)) {
      setProfile(res.data);
    }
  };

  const getNewPosts = async (id: string) => {
    const res = await NewPostService.getNewPost(id);

    if (Network.isSuccess(res.status)) {
      setNewposts(res.data);
    }
  };

  const closeModal = () => {
    setIsVisibleModalProfile(false);
  };

  const closeModalStory = () => {
    setVisibleStoryModal(false);
  };

  const delProfile = async () => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to remove this profile",
      okText: "OK",
      cancelText: "Annuler",
      onOk: async () => {
        try {
          if (id) {
            await DeleteProfileSevice.deleteProfileService(id).then(() => {
              navigate("/pixagram-profiles");
              message.info("Remove profile successfuly!");
            });
          }
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  const delPost = async (postId: string) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Delete post",
      okText: "Supprimer",
      cancelText: "Annuler",
      onOk: async () => {
        try {
          await DeleteNewPostService.deleteNewPost(postId).then(() => {
            message.success("Suppression avec succès");
            id && getNewPosts(id);
          });
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  const updateProfile = () => {
    setIsVisibleModalProfile(true);
    setIsEditInfo(true);
  };

  const showModalUpsertStory = (item?: NewPost) => {
    setNewPost(item);
    setVisibleStoryModal(true);
  };

  return (
    <div>
      <HeaderContent title={"Détails du profil Pixagram"} />

      <Card
        title={
          <Row justify="end">
            <Col flex={1} style={{ textTransform: "capitalize" }}>
              {profile?.profileName}
            </Col>
            <Button
              onClick={delProfile}
              type="primary"
              icon={<DeleteOutlined />}
              danger
            ></Button>
          </Row>
        }
      >
        <Card
          style={styles.cardInner}
          type="inner"
          title={
            <Row justify="end">
              <Button
                type="text"
                onClick={updateProfile}
                icon={<EditOutlined />}
              ></Button>
            </Row>
          }
        >
          <Row gutter={[20, 0]}>
            <Col flex={1} style={{ textAlign: "center" }}>
              <Image
                src={
                  profile?.avatar
                    ? url + profile.avatar
                    : "https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png"
                }
                style={{
                  width: 100,
                  height: 100,
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Col>
            <Col flex={4}>
              <h1
                style={{
                  textTransform: "capitalize",
                  fontSize: "1.2rem",
                  width: 350,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Nom du profil: {profile?.profileName}
              </h1>
              <p>Statut: {profile?.status}</p>
              {profile?.info ? (
                <Row className="icon-info-profile">
                  <p>
                    Star <b>{profile?.info[0]}</b>
                  </p>
                  <p>
                    Following <b>{profile?.info[1]}</b>
                  </p>
                  <p>
                    Follower <b>{profile?.info[2]}</b>
                  </p>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Card>

        <Card
          title={
            <Row>
              <Col flex={1}>
                <b>Nouvelle Story</b>
              </Col>
              <Col>
                <Space>
                  <Button
                    onClick={() => showModalUpsertStory()}
                    type="text"
                    icon={<AppstoreAddOutlined />}
                  ></Button>
                </Space>
              </Col>
            </Row>
          }
          style={styles.cardInner}
          type="inner"
        >
          <Row>
            {newposts.map((item) => (
              <Col
                className="newpost-container"
                key={item._id}
                style={{ textAlign: "center", margin: "0 10px" }}
              >
                <Image
                  key={item._id}
                  width={80}
                  style={{ height: 80, borderRadius: "50%" }}
                  src={url + item.image}
                />
                <h6
                  style={{
                    textTransform: "capitalize",
                    width: 150,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.text}
                </h6>
                <Row className="btn-newpost-content">
                  <Button
                    key="btnEditNewPost"
                    className="fsa"
                    onClick={() => showModalUpsertStory(item)}
                    type="text"
                    icon={<EditOutlined />}
                  ></Button>
                  <Button
                    key="btnDelete"
                    onClick={() => delPost(item._id)}
                    type="text"
                    icon={<DeleteOutlined />}
                  ></Button>
                </Row>
              </Col>
            ))}
          </Row>
        </Card>
        <NewFeedPixigram npcId={profile && profile.npcId} />
      </Card>

      <UpsertProfile
        visible={isVisibleModalProfile}
        onCancel={closeModal}
        getProfile={() => id && getProfile(id)}
        profile={profile}
        isEditInfo={isEditInfo}
      />

      <UpsertStory
        onCancel={closeModalStory}
        visible={visibleStoryModal}
        profileId={profile && profile._id}
        getNewPost={() => id && getNewPosts(id)}
        newPost={newPost}
      />
    </div>
  );
};
