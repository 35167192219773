import { authTypes } from "../../actionTypes/authTypes";
import { AuthActions, AuthState } from "../../types/authTypes";

const initialState: AuthState = {
  pending: false,
  isAuthenticated: false,
  error: null,
  user: null,
};

export default (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case authTypes.FETCH_AUTH_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case authTypes.FETCH_AUTH_SUCCESS:
      return {
        ...state,
        pending: false,
        isAuthenticated: true,
        user: action.payload.user,
        error: null,
      };
    case authTypes.FETCH_AUTH_FAILURE:
      return {
        ...state,
        pending: false,
        isAuthenticated: false,
        user: null,
        error: action.payload?.error,
      };
    case authTypes.LOGOUT_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return {
        ...state,
      };
  }
};
