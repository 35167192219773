import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/reducers/rootReducer";
import { Navigate, useLocation } from "react-router-dom";
import { Account } from "../Models";

interface Props {
  children?: any;
}

export default function RequireAuth(props: Props) {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  return isAuthenticated === true ? (
    props.children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}