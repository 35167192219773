import { AxiosResponse } from 'axios'
import { Category } from '../Models'
import { Network } from './network'

export class CategoryService {
  static async getCategories(): Promise<AxiosResponse<Category[]>> {
    return await Network.getWithToken<Category[]>({ url: '/category' })
  }
}

export class CreateCategory {
  static async createCategory(id: string, data: FormData) : Promise<AxiosResponse<Category>>{
    return await Network.postWithToken<Category>({
      url: 'category/',
      data: data
    })
  }
}