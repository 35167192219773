import { Modal, Image, Button, Avatar, Row, Col, List } from 'antd'
import { useEffect, useState } from 'react'
import { Contact } from '../Models'
import { NewFeed } from '../Models/GameSource/NewFeed'
import { Network } from '../services'
import { ContactSevice } from '../services/Contacts/ContactSevice'
import { BASE_URL } from '../utils/Constants'

interface Props {
  newfeed?: NewFeed
  isVisible: boolean
  closeModal: () => void
  npcId?: string
}

export const ViewNewFeed = (props: Props) => {
  const { newfeed, closeModal, isVisible, npcId } = props
  const [npc, setNpc] = useState<Contact>()

  useEffect(() => {
    fetchContact()
  }, [npcId])

  const fetchContact = async () => {
    try {
      const res = await ContactSevice.getContactService()
      if (Network.isSuccess(res.status)) {
        res.data.forEach((item) => {
          if (npcId && npcId === item._id) {
            setNpc(item)
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      forceRender
      footer={[
        <Button key="btnClose" onClick={closeModal}>
          Close
        </Button>,
      ]}
      title="New Feed"
      width={768}
      visible={isVisible}
      onCancel={closeModal}
    >
      <Row>
        <Avatar size="large" src={npc && BASE_URL + '/' + npc?.image} />
        <h1 style={{ marginLeft: 20 }}>{npc?.name}</h1>
      </Row>
      <Image.PreviewGroup>
        {newfeed &&
          newfeed.image.map((item) => (
            <Image
              key={newfeed.image.indexOf(item)}
              width={200}
              style={{ height: 200, objectFit: 'cover' }}
              src={npc && BASE_URL + '/' + item}
            />
          ))}
      </Image.PreviewGroup>
      <Col style={{ marginTop: 20 }}>{newfeed?.caption}</Col>
      <Col style={{ marginTop: 20 }}>
        <List>
          {newfeed &&
            newfeed.comment.map((item) => (
              <List.Item key={newfeed.comment.indexOf(item)}>
                <List.Item.Meta
                  title={item.userName}
                  description={item.content}
                />
              </List.Item>
            ))}
        </List>
      </Col>
    </Modal>
  )
}
